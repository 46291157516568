import { RouteRecordRaw } from "vue-router";

import BuildingSite from './pages/BuildingSite.vue';
import BuildingSiteDetail from './pages/BuildingSiteDetail.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/buildingsite',
        component: BuildingSite,
        meta: {
            requiresAuth: true,
            menu: {
                label: 'Cantieri',
                iconAsset: 'icons/ico_cantieri.svg'
            }
        }
    },
    {
        path: "/buildingsite/detail/:id",
        component: BuildingSiteDetail,
        meta: {
            requiresAuth: true,
        }
    }
];

export default routes;