<template>
    <Page :loading="loading">
        <div class="row p-3 bg-light">
            <div class="col-12 col-md-4 col-lg-2 d-flex flex-column">
                <form class="upload-dragdrop" method="post" action="" enctype="multipart/form-data" id="uploadChangeStateTarget" data-bs-upload-dragdrop>
                    <div class="upload-dragdrop-image">
                        <!-- <img :src="ddIcon" class="" alt="descrizione immagine" aria-hidden="true"> -->
                        <img src="/upload-drag-drop-icon.svg" class="" alt="descrizione immagine" aria-hidden="true">
                        <div class="upload-dragdrop-loading">
                            <div class="progress-donut" data-bs-progress-donut></div>
                        </div>
                        <div class="upload-dragdrop-success">
                            <KIcon icon="it-check" aria-hidden="true" />
                        </div>
                    </div>
                    <input value="Submit" type="submit" class="d-none" />
                </form>
                <a class="ml-4 mt-5" role="button" title="Cambia Immagine" @click="testAnimation">
                    <span class="text">CAMBIA IMMAGINE
                        <span class="visually-hidden">CAMBIA IMMAGINE</span>
                    </span>
                </a>
            </div>
            <div class="col-12 col-md-5 col-lg-6 d-flex flex-column mt-4">
                <h5>Ragione Sociale</h5>
                <h2>{{company.businessName}}</h2>
                <h5>Iscritto a CESF dal <b>{{iscrittoDal}}</b></h5>
            </div>
            <div class="col-12 col-md-3 col-lg-4 d-flex align-items-center ">
                <button type="button" class="btn btn-primary px-5 col-12">CONSULTA L'ESPERTO CESF</button>
            </div>
        </div>
        <!-- <div class="row px-3 py-3 border-1 d-flex align-items-center mt-3">
            <div class="col-12 col-md-4 col-lg-2">
                <h3 class="m-0">Asseverazione</h3>
            </div>
            <div class="col-12 col-md-4 col-lg-2">
                <h6 class="">Rilasciato in data</h6>
                <h5>{{dataRilascio}}</h5>
            </div>
            <div class="col-12 col-md-4 col-lg-2">
                <div class="profile-payment-state inline-block mr-4" :class="`bg-${codiceStatoPagamento}`"></div>
                <h5 class="m-0 inline-block" :class="`text-${codiceStatoPagamento}`"><b class="text-black">Stato:</b> {{statoPagamento}}</h5>
            </div>
            <div class="col-12 col-md-4 col-lg-2">
                <h5 class="m-0">€: {{toStringDecimalNumber(quotaPagata)}}</h5>
            </div>
            <div class="col-12 col-md-3 col-lg-4">
                <button type="button" class="btn btn-primary px-5 col-12">VISUALIZZA DOCUMENTO</button>
            </div>
        </div> -->
        <div v-if="isRegistryDataInEditMode" class="row px-3 py-3 border-1 mt-3">
            <form
                id="formEditRegistryData"
                class="needs-validation"
                autocomplete="off"
            >
                <div class="row">
                    <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                        <h3 class="m-0">Modifica dati anagrafica</h3>
                        <a @click="stopEditRegistryData" class="btn btn-xs btn-icon p-0" role="button">
                            <KIcon class="icon-primary" icon="it-close-big" />
                            <span class="ml-4 text-primary">ANNULLA</span>
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="cols-12 col-md-12 col-lg-4">
                        <KInputText id="companyFiscalCode" v-model="companyToSave.fiscalCode" labelText="Codice Fiscale *"></KInputText>
                    </div>
                    <div class="cols-12 col-md-12 col-lg-4">
                        <KInputText id="companyPartitaIVA" v-model="companyToSave.partitaIVA" labelText="Partita IVA *"></KInputText>
                    </div>
                </div>
                <div class="row">
                    <div class="cols-12 col-md-12 col-lg-4">
                        <KInputText v-model="companyToSave.address" labelText="Indirizzo"></KInputText>
                    </div>
                    <div class="cols-12 col-md-12 col-lg-4">
                        <KInputText v-model="companyToSave.city" labelText="Comune"></KInputText>
                    </div>
                    <div class="cols-12 col-md-12 col-lg-2">
                        <KInputText v-model="companyToSave.zipCode" labelText="CAP"></KInputText>
                    </div>
                    <div class="cols-12 col-md-12 col-lg-2">
                        <KInputText v-model="companyToSave.provincia" labelText="Provincia"></KInputText>
                    </div>
                </div>
                <div v-if="!isRegistryDataValid" class="row">
                    <div class="col">
                        <KNotificationCard :title="'Dati invalidi:'" :body="registryDataValidationMessage" :level="NotiticationLevel.danger"></KNotificationCard>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button @click="saveCompanyRegistryData" class="btn btn-primary mt-2" type="submit">SALVA</button>
                    </div>
                </div>
            </form>
        </div>
        <div v-else class="row px-3 py-3 border-1 mt-3">
            <div class="col-12 d-flex justify-content-between mb-5 position-relative">
                <h3 class="m-0">Dati Anagrafici</h3>

                <a @click="startEditRegistryData" class="btn btn-xs btn-icon p-0" role="button">
                    <KIcon class="icon-primary" icon="it-pencil" />
                    <span class="ml-4 text-primary">MODIFICA</span>
                </a>

                <!-- <KIcon class="icon-primary icon-xl position-absolute icon-chat-anagrafica" icon="it-comment" /> -->
            </div>
            <div class="col-12 col-md-5">
                <h6 class="">Codice Fiscale</h6>
                <h5>{{company.fiscalCode}}</h5>
            </div>
            <div class="col-12 col-md-5">
                <h6 class="">Partita Iva</h6>
                <h5>{{company.partitaIVA}}</h5>
            </div>
            <div class="col-12 col-md-5">
                <h6 class="">Indirizzo</h6>
                <h5>{{company.address}}</h5>
            </div>
            <div class="col-12 col-md-5">
                <h6 class="">CAP</h6>
                <h5>{{company.zipCode}}</h5>
            </div>
            <div class="col-12 col-md-5">
                <h6 class="">Comune</h6>
                <h5>{{company.city}}</h5>
            </div>
            <div class="col-12 col-md-5">
                <h6 class="">Provincia</h6>
                <h5>{{company.provincia}}</h5>
            </div>
        </div>
        <div v-if="isContactsInEditMode" class="row px-3 py-3 border-1 mt-3">
            <div class="row">
                <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                    <h3 class="m-0">Modifica contatti</h3>
                    <a @click="stopEditContacts" class="btn btn-xs btn-icon p-0" role="button">
                        <KIcon class="icon-primary" icon="it-close-big" />
                        <span class="ml-4 text-primary">ANNULLA</span>
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.phone" labelText="Telefono"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.cellPhone" labelText="Cellulare"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.fax" labelText="Fax"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.email" labelText="E-mail"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.pec" labelText="PEC"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                    <h3 class="m-0">Modifica dati consulente</h3>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.consultantName" labelText="Nome"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.consultantLastName" labelText="Cognome"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.consultantPhone" labelText="Telefono"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.consultantEmail" labelText="E-mail"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="companyToSave.consultantFax" labelText="Fax"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button @click="saveCompanyContacts" class="btn btn-primary mt-2">SALVA</button>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row px-3 py-3 border-1 mt-3">
                <div class="col-12 d-flex justify-content-between mb-5">
                    <h3 class="m-0">Contatti</h3>

                    <a @click="startEditContacts" class="btn btn-xs btn-icon p-0" role="button">
                        <KIcon class="icon-primary" icon="it-pencil" />
                        <span class="ml-4 text-primary">MODIFICA</span>
                    </a>
                </div>
                <div class="col-12 col-md-5">
                    <h6 class="">Telefono</h6>
                    <h5>{{company.phone}}</h5>
                </div>
                <div class="col-12 col-md-5">
                    <h6 class="">Fax</h6>
                    <h5>{{company.fax}}</h5>
                </div>
                <div class="col-12 col-md-5">
                    <h6 class="">Email</h6>
                    <h5>{{company.email}}</h5>
                </div>
                <div class="col-12 col-md-5">
                    <h6 class="">PEC</h6>
                    <h5>{{company.pec}}</h5>
                </div>
                <div class="col-12 col-md-5">
                    <h6 class="">Cellulare</h6>
                    <h5>{{company.cellPhone}}</h5>
                </div>
                <div class="col-12 col-md-5">
                    <h6 class="">Consulente Aziendale</h6>
                    <h5>{{company.consultantName}} {{company.consultantLastName}}</h5>
                </div>
            </div>
            <div class="row px-3 py-3 border-1 d-flex align-items-center mt-3">
                <div class="col-12 col-md-4 col-lg-3">
                    <h3 class="m-0">Il tuo consulente</h3>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <h5 class="m-0">{{company.consultantName}} {{company.consultantLastName}}</h5>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <h6>Telefono</h6>
                    <h5 class="">{{company.consultantPhone}}</h5>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <h6 class="m-0">email</h6>
                    <h5 class="">{{company.consultantEmail}}</h5>
                </div>
            </div>
        </div>
        <div class="row px-3 py-3 border-1 d-flex mt-3">
            <div class="col-12 d-flex justify-content-between mb-5">
                <h3 class="m-0">Descrizione e Specializzazioni</h3>

                <a class="btn btn-xs btn-icon p-0" role="button">
                    <KIcon class="icon-primary" icon="it-pencil" />
                    <span class="ml-4 text-primary">MODIFICA</span>
                </a>
            </div>
            <div class="col-12 ">
                <h6>Descrizione</h6>
            </div>
            <div class="col-12 ">
                <p>{{descrizioneAzienda}}</p>
            </div>
            <div class="col-12 ">
                <h6>Specializzazioni</h6>
            </div>
            <div class="col-12 ">
                <div class="chip chip-simple chip-lg" v-for="(sp, index) in specializzazioni" :key="index">
                    <span class="chip-label text-black ">{{sp}}</span>
                </div>
            </div>
        </div>
        <div class="row px-3 py-3 border-1 d-flex align-items-center mt-3">
            <div class="col-12 col-md-4 col-lg-3">
                <h3 class="m-0">Certificazioni</h3>
            </div>
            <div class="col-12 col-md-4 col-lg-6">
                <ul class="m-0">
                    <li v-for="(cer, index) in certificazioni" :key="index">
                        {{cer}}
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-4 col-lg-3">
                <button type="button" class="btn btn-primary px-5 col-12">DETTAGLI</button>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">

//IMPORTS GENERAL
import { nextTick, onMounted, ref, watch } from "vue";
import { UploadDragDrop, FormValidate } from 'bootstrap-italia';

//IMPORTS MODELS
import { profileService } from "../service";
import { registriesService } from "@/learning/registries/RegistriesService";
import { SaveCompanyRequest } from "@/learning/registries/Requests";
import { Company } from "@/learning/registries/Models";

//IMPORTS KAPPA
import { NotiticationLevel, useFilters } from "@k-digitale/kappa";

//IMPORT COMPOSABLE
import { useCesfUser, useValidationHelper } from "@/composables";
import { mapCompanyToSaveRequest } from "@/learning/registries/Mappers";

//VARS
let validate : any;

//COMPOSABLES
const { toStringDecimalNumber } = useFilters();
const { cesfUser, isUserCompany } = useCesfUser();
const { isValidFiscalCode, isValidPIVA} = useValidationHelper();

//REFS
const company = ref<Company>({} as Company);
const isRegistryDataInEditMode = ref(false);
const isContactsInEditMode = ref(false);
const isRegistryDataValid = ref(true);
const registryDataValidationMessage = ref("");
const loading = ref(false);
const companyToSave = ref<SaveCompanyRequest>({} as SaveCompanyRequest);
const iscrittoDal = ref("2010");

const descrizioneAzienda = ref(
    "Siamo un’azienda specializzata nei settori della sperimentazione e della applicazione di materiali speciali e tecnologie innovative in edilizia. Nei trenta anni di esperienza, si è sempre costantemente caratterizzata per l’utilizzo di materiali, tecniche e tecnologie particolarmente innovative nel settore della conservazione, ripristino, recupero, restauro e consolidamento sia di edifici civili ed industriali sia del patrimonio storico artistico e monumentale, che nelle infrastrutture."
);
const specializzazioni = ref<any[]>([
    "Consolidamenti",
    "Costruzioni",
    "Demolizioni",
    "Deumidificazioni",
    "Impermeabilizzazioni",
]);
const certificazioni = ref<any[]>([
    "Sistemi di gestione per la Qualità ISO 9001",
    "Sistemi di gestione per la Salute e la Sicurezza su lavoro ISO 45001",
]);

//VUE
onMounted(async () => {
    if(isUserCompany.value){
        loading.value = true;
        company.value = await profileService.getCompany(cesfUser.value.companyId);
        loading.value = false;
    }
});

watch(isRegistryDataInEditMode, async (newValue) => {
    if(newValue){
        await nextTick();
        defineValidations();
    }
});

//METHODS
const defineValidations = () => {
        validate = new FormValidate("#formEditRegistryData", {
        errorFieldCssClass: "is-invalid",
        errorLabelCssClass: "form-feedback",
        errorLabelStyle: "",
        focusInvalidField: true,
    });

    validate.addField("#companyFiscalCode", [
        {
            validator: (value: string) => value ? isValidFiscalCode(value) : true,
            errorMessage: "Il formato del codice fiscale dell'azienda non è valido"
        }
    ]);

    validate.addField("#companyPartitaIVA", [
        {
            validator: (value: string) => value ? isValidPIVA(value) : true,
            errorMessage: "Il formato della partiva IVA dell'azienda non è valido"
        }
    ]);
}

const testAnimation = () => {
    var element = UploadDragDrop.getOrCreateInstance(
        document.getElementById("uploadChangeStateTarget")
    );

    element.start();

    setTimeout(function () {
        element.progress(0.33);
    }, 1000);

    setTimeout(function () {
        element.progress(0.66);
    }, 2000);

    setTimeout(function () {
        element.progress(0.99);
    }, 3000);

    setTimeout(function () {
        element.success();
    }, 4500);
};

const saveCompanyRegistryData = async () => {
    if(!companyToSave.value.fiscalCode && !companyToSave.value.partitaIVA){
        isRegistryDataValid.value = false;
        registryDataValidationMessage.value = 'Almeno uno tra codice fiscale e partita IVA deve essere inserito.';
        return;
    }
    else
    {
        isRegistryDataValid.value = true;
    }

    await validate.revalidate();
    if(validate.isValid){
        loading.value = true;
        const response = await registriesService.saveCompany(companyToSave.value);
        if(response){
            stopEditRegistryData();
            stopEditContacts();
            company.value = await profileService.getCompany(cesfUser.value.companyId);
        }
        loading.value = false;
    }
}

const saveCompanyContacts = async () => {
    loading.value = true;
    const response = await registriesService.saveCompany(companyToSave.value);
    if(response){
        stopEditRegistryData();
        stopEditContacts();
        company.value = await profileService.getCompany(cesfUser.value.companyId);
    }
    loading.value = false;
}

const startEditRegistryData = () => {
    isRegistryDataValid.value = true;
    companyToSave.value = mapCompanyToSaveRequest(company.value);
    isRegistryDataInEditMode.value = true;
}

const stopEditRegistryData = () => {
    companyToSave.value = {} as SaveCompanyRequest;
    isRegistryDataInEditMode.value = false;
}

const startEditContacts = () => {
    companyToSave.value = mapCompanyToSaveRequest(company.value);
    isContactsInEditMode.value = true;
}

const stopEditContacts = () => {
    companyToSave.value = {} as SaveCompanyRequest;
    isContactsInEditMode.value = false;
}
</script>

<style lang="scss">
.profile-image {
    width: 80%;
    height: 80%;
}
.profile-payment-state {
    width: 15px;
    height: 15px;
    border-radius: 10px;
}
.height-title-profile-data {
    height: 40px;
}
.icon-chat-anagrafica {
    right: -50px;
    top: 50px;
}
</style>