<template>
    <KModalSlide  @closed="emit('update:modelValue', false)" v-model="isModalOpen" title="Aggiungi nuovo dipendente">
        <form
            action="#"
            id="formUserData"
            class="needs-validation"
            autocomplete="off"
            >
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-5">
                    <KInputText id="workerName" v-model="newWorker.name" labelText="Nome *"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-5">
                    <KInputText id="workerLastName" v-model="newWorker.lastName" labelText="Cognome *"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-2">
                    <KSelect v-model="newWorker.sex" :options="workerSexDropdownOptions" labelText="Sesso"></KSelect>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KDatePicker inputId="workerBirthDate" :max-date="new Date()" class="w-full" v-model="newWorker.birthdate" labelText="Data di nascita *" />
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText id="workerBirthPlace" v-model="newWorker.birthplace" labelText="Luogo di nascita *"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="newWorker.citizenship" labelText="Cittadinanza"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText id="workerFiscalCode" v-model="newWorker.fiscalCode" labelText="Codice Fiscale *"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="newWorker.telephone" labelText="Telefono"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="newWorker.cellPhone" labelText="Cellulare"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="newWorker.address" labelText="Indirizzo"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KInputText v-model="newWorker.city" labelText="Comune"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-2">
                    <KInputText v-model="newWorker.zipCode" labelText="CAP"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-2">
                    <KInputText v-model="newWorker.provincia" labelText="Provincia"></KInputText>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-5">
                    <KInputText v-model="newWorker.email" labelText="E-mail"></KInputText>
                </div>
                <div class="cols-12 col-md-12 col-lg-4">
                    <KSelect v-model="newWorker.qualification" :options="workerQualificationDropDownOptions" labelText="Qualifica"></KSelect>
                </div>
                <div class="cols-12 col-md-12 col-lg-3">
                    <KSelect v-model="newWorker.status" :options="workerStatusDropDownOptions" labelText="Status"></KSelect>
                </div>
            </div>
            <div class="row">
                <div class="cols-12 col-md-12 col-lg-4">
                    <KDatePicker class="w-full" v-model="newWorker.hiringDate" labelText="Data assunzione" />
                </div>
            </div>
        </form>
        <template #actions>
            <button type="submit" class="btn btn-primary'" @click="saveNewWorker">SALVA</button>
            <button type="button" class="btn btn-outline-primary'" @click="closePanel">ANNULLA</button>
        </template>
    </KModalSlide>
</template>

<script lang="ts" setup>

import { onMounted, ref, watch } from 'vue';

//IMPORT COMPONENTS
import { FormValidate } from "bootstrap-italia";
import { KModalSlide, KSelect, KDatePicker } from '@k-digitale/kappa';

//IMPORT MODELS
import { SaveWorkerRequest } from '@/learning/registries/Requests';

//IMPORT COMPOSABLES
import { useViewStatus, useValidationHelper, useCesfUser } from "@/composables";
import { cesfService } from "@/services/cesf/CesfServices";

//COMPOSABLES
const {
    getWorkerQualificationDropdownOptions,
    getWorkerStatusDropdownOptions,
    getWorkerSexDropdownOptions
} = useViewStatus();
const { isValidFiscalCode } = useValidationHelper();

const { cesfUser } = useCesfUser();

//VARS
let validate : any;

//REFS
const isModalOpen = ref(false);
const newWorker = ref<SaveWorkerRequest>({} as SaveWorkerRequest);
const workerQualificationDropDownOptions = ref<any[]>([{
        name: "Nessuna",
        value: null,
}].concat(getWorkerQualificationDropdownOptions()))
const workerStatusDropDownOptions = ref<any[]>([{
    name: "Nessuno",
    value: null,
}].concat(getWorkerStatusDropdownOptions()))
const workerSexDropdownOptions = ref<any[]>(getWorkerSexDropdownOptions())


//VUE
const props = withDefaults(
  defineProps<{
    modelValue: boolean,
  }>(), {

});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'new-worker-saved'): void
}>()

watch(() => props.modelValue, (newValue) => {
    isModalOpen.value = newValue;
})

onMounted(() => {
    defineValidations();
})

//METHODS
const defineValidations = () => {
    validate = new FormValidate("#formUserData", {
    errorFieldCssClass: "is-invalid",
    errorLabelCssClass: "form-feedback",
    errorLabelStyle: "",
    focusInvalidField: true,
  });

    validate.addField("#workerName", [
        {
            rule: "required",
            errorMessage: "Il nome del dipendente è richiesto",
        },
    ]);
    validate.addField("#workerLastName", [
        {
            rule: "required",
            errorMessage: "Il cognome del dipendente è richiesto",
        },
    ]);
    validate.addField("#workerBirthDate", [
        {
            rule: "required",
            errorMessage: "La data di nascita del dipendente è richiesta",
        },
    ]);
    validate.addField("#workerBirthPlace", [
        {
            rule: "required",
            errorMessage: "Il luogo di nascita del dipendente è richiesto",
        },
    ]);
    validate.addField("#workerFiscalCode", [
        {
            rule: "required",
            errorMessage: "Il codice fiscale del dipendente è richiesto",
        },
        {
            validator: (value: string) => isValidFiscalCode(value),
            errorMessage: "Il formato del codice fiscale del dipendendente non è valido"
        }
    ]);
}

const saveNewWorker = async () => {
    // document.getElementById("formUserData").dispatchEvent(new CustomEvent('submit', {cancelable: true}));
    await validate.revalidate();

    if(validate.isValid)
    {
        newWorker.value.companyId = cesfUser.value.companyId;
        const result =  await cesfService.saveNewWorker(newWorker.value);
        if(result){
            newWorker.value = {} as SaveWorkerRequest;
            closePanel();
            emit("new-worker-saved");
        } else{
            console.log("errore salvataggio dipendente");
        }
    }

}
const closePanel = () => {
    isModalOpen.value = false;
    emit('update:modelValue', false);
}

</script>