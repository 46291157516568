import { BaseFinder } from "@k-digitale/kappa"
import { CourseTemplate, LessonTemplate } from "../Templates/Models"
import { Company, Coordinator, Student, Teacher, Tutor } from "../Registries/Models";

export enum CourseInstanceStatus {
    StudentsSigninIn = "StudentsSigninIn",
    Completed = "Completed",
    Aborted = "Aborted"
}

export interface CourseInstance {
    id: string;
    courseTemplateId: string;
    tutorId: string | null;
    coordinatorId: string | null;
    startDate: Date | null;
    endDate: Date | null;
    code: string;
    protocolNumber: string;
    studentsNumber: number;
    status: CourseInstanceStatus;
    template: CourseTemplate;
    tutor: Tutor;
    coordinator: Coordinator;
    isSendedToFormedil: boolean;
    is16HoursNotRequested: boolean;
}

export interface LessonInstance {
    id: string;
    lessonTemplateId: string;
    courseInstanceId: string;
    teachers: Teacher[] | null;

    hours: number;
    startDate: Date;
    endDate: Date;

    place: string;
    group: string;
    requestedParticipants: number;

    lessonTemplate: LessonTemplate,
    teacher: Teacher | null,
    courseInstance: CourseInstance | null,
}

//COURSE PARTICIPATION REQUESTS
export enum CourseParticipationRequestStatus {
    NotSignedUp = "NotSignedUp",
    SignedUp = "SignedUp"
}
export interface CourseParticipationRequest {
    id: string;
    companyId: string;
    studentId: string;
    courseTemplateId: string;
    timestamp: Date;
    student: Student;
    company: Company;
    courseTemplate: CourseTemplate;
    status: CourseParticipationRequestStatus
}

export enum CourseParticipationStatus {
    SignedUp = "SignedUp",
    Completed = "Completed",
}

export interface CourseParticipation {
    id: string;
    studentId: string;
    companyId: string;
    courseInstanceId: string;
    timestamp: Date;
    courseInstance: CourseInstance;
    student: Student;
    company: Company;
    status: CourseParticipationStatus
}

//COURSE PARTICIPATION OUTCOMES
export enum CourseParticipationOutcomeFrequencyStatus {
    ParticipatedToZeroHours = "ParticipatedToZeroHours",
    NotParticipatedToEnoughHours = "NotParticipatedToEnoughHours",
    Success = "Success",
}
export enum CourseParticipationOutcomeExamStatus {
    NotPresent = "NotPresent",
    Failed = "Failed",
    Success = "Success",
}

export enum CourseParticipationOutcomeStatus {
    Failed = "Failed",
    Success = "Success",
}
export enum CourseParticipationOutcomeValidityStatus {
    Active = "Active",
    Old = "Old",
}

export interface CourseParticipationOutcome {
    id: string;
    courseParticipationId: string;
    relatedCourseTemplateId: string;
    isUpdate: boolean;
    certificateId: number | null;
    certificateCopyId: number | null;
    certificateVersion: number | null;
    certificateProtocolNumber: string;
    expiryDate: Date | null;
    timestamp: Date;
    examDate: Date | null;
    courseDate: Date | null;
    frequencyStatus: CourseParticipationOutcomeFrequencyStatus;
    examStatus: CourseParticipationOutcomeExamStatus;
    doesExpire: boolean;
    notes: string;
    courseParticipation: CourseParticipation;
    relatedCourseTemplate: CourseTemplate;
}

//LESSON PARTICIPATIONS
export enum LessonParticipationStatus {
    Requested = "Requested",
    Absent = "Absent",
    Present = "Present"
}

export interface LessonParticipation {
    id: string;
    courseParticipationId: string;
    courseParticipation: CourseParticipation;
    lessonInstanceId: string;
    status: LessonParticipationStatus;
    entryDate: Date | null;
    exitDate: Date | null;
    timestamp: Date;
}

//FINDERS
export interface CourseInstanceFinder extends BaseFinder {
    queryRelations: boolean;
    courseTemplateId: string | null;
    status: CourseInstanceStatus | null;
    isSendedToFormedil: boolean | null;
    showOldCourseTemplates: boolean | null;
    startAfterDate: Date | null;
    hideOldCourses: boolean;
    endBeforeDate: Date | null;
    globalSearchTerm: string | null;
}

export interface LessonInstanceFinder extends BaseFinder {
    queryCourseRelations: boolean;
    queryRelations: boolean;
    queryRegistriesRelations: boolean;
    courseInstanceId: string | null;
    teacherId: string | null;
}

export interface CourseParticipationRequestFinder extends BaseFinder {
    queryRelations: boolean;
    courseTemplateId: string | null;
    companyId: string | null;
    status: CourseParticipationRequestStatus | null;
    globalSearchTerm: string | null;
}

export interface CourseParticipationFinder extends BaseFinder {
    queryRegistriesRelations: boolean;
    queryInstancesRelations: boolean;
    courseInstanceId: string | null;
    studentId: string | null;
    globalSearchTerm: string | null;
    courseInstanceStatus: CourseInstanceStatus | null;
    status: CourseParticipationStatus | null;
}

export interface LessonParticipationFinder extends BaseFinder {
    queryStudentsRelations: boolean;
    queryCompaniesRelations: boolean;
    queryLessonsRelations: boolean;

    courseInstanceId: string | null;
    lessonInstanceId: string | null;
    status: LessonParticipationStatus | null;
    entryDate: Date | null;
    exitDate: Date | null;
}

export interface CourseParticipationOutcomeFinder extends BaseFinder {
    queryRegistriesRelations: boolean;
    courseParticipationId: string | null;
    studentId: string | null;

    status: CourseParticipationOutcomeStatus | null;
    validityStatus: CourseParticipationOutcomeValidityStatus | null;
    checkExpiringDate: boolean;
}