import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { Cantiere, CantiereFinder } from "@/services/cesf/models";
import { SaveWorkerRequest } from "@/learning/registries/Requests";
import { SendEmailToCesfRequest, SendMessageToCesfRequest } from "./Requests";

class CesfService extends BaseService {

    //CANTIERI
    async getCantieriByEduCompanyId(finder: CantiereFinder, companyId : string): Promise<ApiPagedResult<Cantiere>> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantieri/${companyId}`, { params: finder })
        );

        return response!.data;
    }

    async getCantiere(id: string): Promise<Cantiere> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantiere/${id}`)
        );

        return response!.data;
    }

    //DIPENDENTI
    async saveNewWorker(request: SaveWorkerRequest){
        const response = await this.tryExecute(
            this.api.post<any>(`learning/workers/save-ext`, request)
        );

        return response!.data.success;
        //return await this.handleApiResponse(response?.data);
    }

    // DOCUMENTI CANTIERE
    async uploadDocumentoCantiere(id: string, formData: FormData): Promise<boolean> {

        const response = await this.tryExecute(
            this.api.post<any>(`cesf/cantiere/${id}/upload`, formData)
        );

        return response!.data.success;
        //return await this.handleApiResponse(response?.data);
    }

    async getDocumentiCantiere(id : string): Promise<any> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantiere/${id}/documenti`)
        );

        return response!.data;
    }

    async downloadCantieriDocument(id: string, idDocumento : number, filename:string) {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantiere/${id}/documenti/${idDocumento}`, {
                responseType: 'blob'
            })
        );

        let link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = filename;
        link.target = "_new";
        link.click();
    }

    async deleteDocumentoCantiere(id: string, idDocumento : number): Promise<boolean> {

        const response = await this.tryExecute(
            this.api.post<any>(`cesf/cantiere/${id}/documenti/${idDocumento}/delete`)
        );

        return response!.data.success;
    }

    //NOTIFICATIONS
    async sendEmailToCesf(request: SendEmailToCesfRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/notifications/send-email-to-cesf", request)
        );

        //return response!.data;
        return response!.data.success;
    }

    async sendMessageToCesf(request: SendMessageToCesfRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/notifications/send-message-to-cesf", request)
        );

        //return response!.data;
        return response!.data.success;
    }
}

export const cesfService = new CesfService();
