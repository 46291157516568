import { defineStore } from "pinia";
import { CourseInstance, CourseInstanceFinder, CourseInstanceStatus, CourseParticipationOutcome, CourseParticipationOutcomeFinder } from '@/learning/instances/models';
import { courseService } from './service';
import { instancesService } from '@/learning/instances/instancesService';
import { registriesService } from '@/learning/registries/registriesService';
import { usePlatformApi } from "@k-digitale/kappa";
import { SaveCourseParticipationRequestListItemRequest } from "./requests";
import dayjs from "dayjs";

const { execApiCall } = usePlatformApi();
export interface ModuleCourseState {
  loadingCompanyOutcomes: boolean,
  loadingStartingCourses: boolean,
  startingCourses: CourseInstance[]
  companyOutcomes: CourseParticipationOutcome[]
}

export const useCourseStore = defineStore('module-course-store', {
  state: () => ({
    loadingCompanyOutcomes: false,
    loadingStartingCourses: false,
    startingCourses:[] as CourseInstance[],
    companyOutcomes: [],
  } as ModuleCourseState),

  getters: {
      //
  },

  actions: {
      async loadStartingCourses(status: string, queryRelations: boolean, startAfterDate: Date) {
          const courses = await execApiCall(courseService.getStartingCourses(status, queryRelations, startAfterDate));
          if(courses && courses.items && courses.items.length)
          {
              this.startingCourses = courses.items;
          }
      },

      async saveCourseParticipationRequestsSave(request : SaveCourseParticipationRequestListItemRequest[]) : Promise<boolean> {
        return await execApiCall(instancesService.saveCourseParticipationRequestList(request));
      },
      async getCompanyCourseOutcomesByFinder(companyId: string, finder : CourseParticipationOutcomeFinder) : Promise<CourseParticipationOutcome[]> {
        this.loadingCompanyOutcomes = true;
        const courseParticipationsResponse = await registriesService.getCourseParticipationOutcomesWithActualStudents(companyId, finder);
        this.loadingCompanyOutcomes = false;
        if(courseParticipationsResponse && courseParticipationsResponse.items){
          this.companyOutcomes = courseParticipationsResponse.items;
          return courseParticipationsResponse.items;
        }
        return [];
      },

      async getStartingCourses(finder : CourseInstanceFinder) : Promise<CourseInstance[]> {
        finder.startAfterDate = dayjs().utc(true).toDate();
        finder.status = CourseInstanceStatus.StudentsSigninIn;

        this.loadingStartingCourses = true;
        const startingCoursesResponse = await instancesService.getCourseInstancesByFinder(finder);
        this.loadingStartingCourses = false;
        if(startingCoursesResponse && startingCoursesResponse.items){
          this.startingCourses = startingCoursesResponse.items;
          return startingCoursesResponse.items;
        }
        return [];
      }
  }
})