import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { CompanyExtension } from "@/services/learningExtensions/models";

class LearningExtensionsServices extends BaseService {

    //COMPANIES
    async getCompanyExtByEduCompanyId(companyId : string): Promise<CompanyExtension> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/ext/company/${companyId}`)
        );

        return response!.data;
    }

}

export const learningExtensionService = new LearningExtensionsServices();
