<template>
    <Page>
        <PageHeader title="Cantieri"></PageHeader>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 d-flex d-inline-flex">
                <div class="form-group col-12 m-0">
                    <div class="input-group">
                        <label for="input-group-3">Cerca cantiere</label>
                        <input type="text" class="form-control" id="input-group-3" name="input-group-3" />
                        <div class="input-group-append">
                            <button class="btn btn-secondary btn-icon p-2">
                                <KIcon class="icon-white" icon="it-search" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="me-auto col-12 col-md-6 col-lg-6 d-inline-flex align-items-center justify-content-end">
                <label>Filtra </label>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary btn-outline-secondary dropdown-toggle text-black" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Comune
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary dropdown-toggle btn-outline-secondary text-black" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Tipologia
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <DataTable :value="rowItems" dataKey="id" responsiveLayout="scroll" :loading="moduleBuildingSiteStore.loadingCantieri">
                    <Column field="comune" header="COMUNE"></Column>
                    <Column field="tipologia" header="TIPOLOGIA"></Column>
                    <Column header="INIZIO">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.dataInizio)}}
                        </template>
                    </Column>
                    <Column header="FINE">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.dataFine)}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <button @click="goToCantiereDetail(slotProps.data)" type="button" class="btn btn-primary"> DETTAGLI </button>
                            </div>
                        </template>
                    </Column>
                    <template #empty> Nessun attestato trovato </template>
                </DataTable>
            </div>
        </div>

        <BannerEsperto titolo="Hai bisogno di attivare un servizio per cantiere?" testo-btn="CONTATTA IL TU CONSULENTE CESF" />
    </Page>
</template>

<script setup lang="ts">

import { ref, watch, nextTick, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useFilters } from "@k-digitale/kappa";
import { useCesfUser } from "@/composables";
import { useBuildingSiteStore } from "../store"
import { Cantiere, CantiereFinder } from "@/services/cesf/models";
import BannerEsperto from "@/components/BannerEsperto.vue";

//EXT
const { toDateString } = useFilters();

//COMPOSABLES
const { cesfUser, isUserCompany } = useCesfUser();
const router = useRouter();

//STORES
const moduleBuildingSiteStore = useBuildingSiteStore();


const goToCantiereDetail = (item: Cantiere) => {
    router.push({
        path: `/buildingsite/detail/${item.id}`
    });
};
//REFS
const rows = ref(10);
const cantieri = ref<Cantiere[]>([]);

//COMPUTED
const rowItems = computed(() => {
    return cantieri.value.map(c => {
            return {
                id: c.id,
                comune: c.comuneISTAT + ` (${c.provinciaISTAT})`,
                tipologia: c.tipologiaDeiLavori,
                dataInizio: c.dataPresuntoInizio,
                dataFine: c.dataPresuntaFine,
            };
        });
})

//VUE
onMounted(async () => {
    await loadCantieri();
});

//METHODS
const onPageChange = (event: any) => {
    if (event && event.originalEvent) {
        var newPage = event.originalEvent.page;

        // var finder: StudentsFinder = {
        //     pageIndex: newPage,
        //     pageSize: rows.value,
        // };

        loadCantieri(newPage);
    }
};

const loadCantieri = async (pageNumber : number = 1) => {
    const finder = {
        pageIndex: pageNumber,
        pageSize: 10
    } as CantiereFinder;
    if(isUserCompany.value){
        const result = await moduleBuildingSiteStore.getCantieriByCompany(finder, cesfUser.value.companyId);
        cantieri.value = result.items;
    }
}


</script>

<style lang="scss">
.tb-lista-cantieri .p-datatable-wrapper {
    table {
        thead {
            tr {
                th {
                    border: none;
                }
            }
        }
    }
}
</style>
