<template>
    <Page>
        <PageHeader title="Corsi"></PageHeader>
        <!-- <div class="row">
            <div class="col">
                <KNotificationCard :title="'Attestato scaduto:'" :body="'Lorem ipsum dolor sit amet, consectertur adipiscing elit.'" :level="NotiticationLevel.danger" :showPrimaryButton="true" :primaryButton="{ label: 'DETTAGLI' }"></KNotificationCard>
            </div>
        </div> -->
        <div class="row">
            <div class="col d-inline-flex justify-content-between">
                <h3>Corsi in Partenza</h3>
                <a class="read-more d-none d-lg-flex" role="button">
                    TUTTI I CORSI
                    <KIcon class="icon-xs" icon="it-arrow-right" />
                </a>
            </div>
        </div>
        <div class="row">
            <p v-if="startingCourses.length == 0">Non sono presenti al momento corsi in partenza.</p>
            <div class="col-12 col-md-6 col-lg-4 pt-3 py-lg-3" v-for="(cc, index) in startingCourses" :key="index">
                <KCard :title="cc.template.title"
                    :topTitle="`${cc.template.totalHours} ${cc.template.totalHours > 1 ? 'ORE' : 'ORA'}`"
                    topTitleIcon="it-clock" :showDetailButton="true" :showCmdButton="true"
                    labelCmdButton="ISCRIVITI"
                    :eventCmdButton="() => goToCourseDetail(cc.id)">

                </KCard>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6 d-flex d-inline-flex">
                <h3 class="m-0">I miei corsi</h3>
                <div class="form-group ml-5 mb-0">
                    <div class="input-group">
                        <label for="input-group-3">Cerca Corso</label>
                        <input type="text" class="form-control" id="input-group-3" name="input-group-3" />
                        <div class="input-group-append">
                            <button class="btn btn-secondary btn-icon p-2">
                                <KIcon class="icon-white" icon="it-search" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="me-auto col-12 col-md-6 col-lg-6 d-inline-flex align-items-center justify-content-end">
                <label>Filtra per</label>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary btn-outline-secondary dropdown-toggle text-black"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Data Scadenza
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary dropdown-toggle btn-outline-secondary text-black"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Stato
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-borderless">
                    <thead class="table-dark">
                        <tr>
                            <th scope="col" style="max-width: 200px">CORSO</th>
                            <th scope="col">NOMINATIVO</th>
                            <th scope="col">DATA SCADENZA</th>
                            <th scope="col">STATO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="myCourses.length > 0" v-for="(mc, index) in myCourses" :key="index">
                            <tr>
                                <td colspan="3">
                                    <div class="accordion accordion-left-icon border-0" id="accordionMyCourses">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" :id="`myCourseHeading${index}`">
                                                <button class="accordion-button border-0 text-black" type="button"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="`#myCourseCollapse${index}`" aria-expanded="false"
                                                    :aria-controls="`myCourseCollapse${index}`">
                                                    {{ mc.name }}
                                                </button>
                                            </h2>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a class="read-more mt-3 float-end" role="button" title="VAI AL CORSO">
                                        <span class="text">
                                            VAI AL CORSO
                                            <span class="visually-hidden">VAI AL CORSO</span>
                                        </span>
                                        <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                    </a>
                                </td>
                            </tr>
                            <tr v-for="(dd, indexRow) in mc.details" :key="indexRow" :id="`myCourseCollapse${index}`"
                                class="accordion-collapse collapse" :class="indexRow > 0 ? 'border-top' : ''"
                                data-bs-parent="#accordionMyCourses" role="region"
                                :aria-labelledby="`myCourseHeading${index}`">
                                <td style="max-width: 200px"></td>
                                <td>
                                    <div class="accordion-body p-0">{{ dd.userFullName }}</div>
                                </td>
                                <td>
                                    <div class="accordion-body p-0">{{ dd.expireDate }}</div>
                                </td>
                                <td>
                                    <div class="course-state-code inline-block mr-4" :class="`bg-${dd.stateCode}`">
                                    </div>
                                    <div class="accordion-body p-0 inline-block">{{ dd.stateDescription }}</div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td>Non sono presenti al momento corsi effettuati.</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <!-- <section class="it-hero-wrapper it-hero-small-size bg-warning bg-gradient" aria-label="In evidenza">
                    <div class="row w-100">
                        <div class="col-12">
                            <div class="it-hero-text-wrapper bg-dark">
                                <h1>Non sai quale corso scegliere?</h1>
                                <div class="it-btn-container m-0"><a class="btn btn-sm btn-outline-primary MX-5" href="#">CHIEDI AL TUO CONSULENTE</a></div>
                            </div>

                        </div>
                    </div>
                </section> -->
                <div class="card bg-warning bg-gradient text-white" style="height: 200px">
                    <KIcon class="icon-white position-absolute h-100" icon="it-telephone" />
                    <div class="card-img-overlay">
                        <h1 class="mt-5 ml-5">Non sai quale corso scegliere?</h1>
                        <div class="it-btn-container mt-2 ml-5 text-white"><a class="btn btn-sm btn-outline-white MX-5"
                                href="#">CHIEDI AL TUO CONSULENTE</a></div>
                    </div>
                </div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { nextTick, onMounted, ref } from "vue";
import { CourseInstance, CourseInstanceStatus, CourseParticipationOutcome, CourseParticipationOutcomeFinder, CourseParticipationOutcomeStatus, CourseParticipationOutcomeValidityStatus } from "@/learning/instances/models";
import { courseService } from "../service";
import { registriesService } from "@/learning/registries/registriesService";
import { useCourseStore } from "../store";
import { useCesfUser, useViewStatus } from "@/composables";
import { NotiticationLevel, useBootstrapItalia, useFilters } from "@k-digitale/kappa";
import { useRouter } from "vue-router";

const router = useRouter();
const { toDateString } = useFilters();
const courseStore = useCourseStore();
const { cesfUser, isUserCompany } = useCesfUser();
const viewStatusHelper = useViewStatus();

const startingCourses = ref<CourseInstance[]>([]);
const myCourses = ref<any[]>([]);

const { biAccordion } = useBootstrapItalia();

onMounted(async () => {
    //await courseStore.loadStarterCourses(CourseInstanceStatus.StudentsSigninIn.toString(), true, new Date());

    startingCourses.value = await (await courseService.getStartingCourses(CourseInstanceStatus.StudentsSigninIn.toString(), true, dayjs().toDate())).items;

    if(isUserCompany){
        const courseParticipationsResponse = await registriesService.getCourseParticipationOutcomesWithActualStudents(cesfUser.value.companyId,
            {
                queryRegistriesRelations: true,
                pageIndex: -1,
                pageSize: -1,
                status: CourseParticipationOutcomeStatus.Success,
                validityStatus: CourseParticipationOutcomeValidityStatus.Active,
                sortOrder: -1
            } as CourseParticipationOutcomeFinder);
        if(courseParticipationsResponse.items.length > 0){
            const groupedParticipations = courseParticipationsResponse.items.reduce((acc : any, obj : CourseParticipationOutcome) => {
                const key = obj.relatedCourseTemplateId;
                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(obj);
                return acc;
            }, {});
            for (var key of Object.keys(groupedParticipations)) {
                const group = groupedParticipations[key];
                const studentsArray = (groupedParticipations[key] as CourseParticipationOutcome[]).map(cp =>{
                    return {
                        userFullName: cp.courseParticipation.student.worker.name+ ' ' + cp.courseParticipation.student.worker.lastName,
                        expireDate: toDateString(cp.expiryDate),
                        stateCode: viewStatusHelper.getStatusCodeFromDate(cp.expiryDate),
                        stateDescription: viewStatusHelper.getStatusLabelFromDate(cp.expiryDate)
                    }
                });
                myCourses.value.push(
                    {
                        name: (group[0] as CourseParticipationOutcome).relatedCourseTemplate.title,
                        details: studentsArray,
                    }
                );
            }
        }

        nextTick(() => {
            var triggerAccordions = [].slice.call(document.querySelectorAll(`.accordion`))
            triggerAccordions.forEach(function (triggerEl) {
                biAccordion(triggerEl);
            })
        });
    }

    // myCourses.value.push(
    //     {
    //         name: "Corso di sicurezza base",
    //         details: [
    //             {
    //                 code: 5370,
    //                 userFullName: "Mario Rossi",
    //                 expireDate: "24/04/2022",
    //                 stateCode: "warning",
    //                 stateDescription: "Esame mancante"
    //             },
    //             {
    //                 code: 5372,
    //                 userFullName: "Luigi Bianchi",
    //                 expireDate: "12/11/2021",
    //                 stateCode: "danger",
    //                 stateDescription: "Scaduto"
    //             }
    //         ]
    //     },
    //     {
    //         name: "Addetto all'antincendio medio rischio",
    //         details: [
    //             {
    //                 code: 5370,
    //                 userFullName: "Mario Rossi",
    //                 expireDate: "24/04/2022",
    //                 stateCode: "warning",
    //                 stateDescription: "Esame mancante"
    //             },
    //             {
    //                 code: 5372,
    //                 userFullName: "Luigi Bianchi",
    //                 expireDate: "12/11/2021",
    //                 stateCode: "danger",
    //                 stateDescription: "Scaduto"
    //             }
    //         ]
    //     },
    //     {
    //         name: "Progetto 16 ore",
    //         details: [
    //             {
    //                 code: 5370,
    //                 userFullName: "Mario Rossi",
    //                 expireDate: "24/04/2022",
    //                 stateCode: "warning",
    //                 stateDescription: "Esame mancante"
    //             },
    //             {
    //                 code: 5372,
    //                 userFullName: "Luigi Bianchi",
    //                 expireDate: "12/11/2021",
    //                 stateCode: "danger",
    //                 stateDescription: "Scaduto"
    //             }
    //         ]
    //     },
    //     {
    //         name: "Montaggio, smontaggio e Trasformazione Ponteggi",
    //         details: [
    //             {
    //                 code: 5370,
    //                 userFullName: "Mario Rossi",
    //                 expireDate: "24/04/2022",
    //                 stateCode: "warning",
    //                 stateDescription: "Esame mancante"
    //             },
    //             {
    //                 code: 5372,
    //                 userFullName: "Luigi Bianchi",
    //                 expireDate: "12/11/2021",
    //                 stateCode: "danger",
    //                 stateDescription: "Scaduto"
    //             }
    //         ]
    //     },
    //     {
    //         name: "Addetto al primo soccorso",
    //         details: [
    //             {
    //                 code: 5370,
    //                 userFullName: "Mario Rossi",
    //                 expireDate: "24/04/2022",
    //                 stateCode: "warning",
    //                 stateDescription: "Esame mancante"
    //             },
    //             {
    //                 code: 5372,
    //                 userFullName: "Luigi Bianchi",
    //                 expireDate: "12/11/2021",
    //                 stateCode: "danger",
    //                 stateDescription: "Scaduto"
    //             }
    //         ]
    //     }
    // );
});

const goToCourseDetail = (id: string) => {
    router.push({
        path: `/course/registration/${id}`,
    });
};
</script>

<style lang="scss">
.go-to-course-accordion {
    top: 19px;
    right: 70px;
}

.course-state-code {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
</style>
