<template>
    <Page>
        <KBackButton />
        <PageHeader :title="'Cantiere '+cantiere?.codice"></PageHeader>

        <div class="row mx-0" v-if="cantiere">
            <div class="col-12">
                <div class="row d-flex align-items-center mt-3 bg-light py-3">
                    <div class="col-12 col-md-5 col-lg-4">
                        <h6 class="">Tipologia</h6>
                        <h5>{{ cantiere.tipologiaDeiLavori }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Inizio</h6>
                        <h5>{{ toDateString(cantiere.dataPresuntoInizio) }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Data Assunzione</h6>
                        <h5>{{ toDateString(cantiere.dataPresuntaFine) }}</h5>
                    </div>
                    <div v-if="cantiere.nomeResponsabile" class="col-12 col-md-5 col-lg-4">
                        <h6 class="">Responsabile Cantiere</h6>
                        <h5>{{ cantiere.nomeResponsabile }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Comune</h6>
                        <h5>{{ cantiere.comuneISTAT }} ({{cantiere.provinciaISTAT}})</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Indirizzo</h6>
                        <h5>{{ cantiere.via }}</h5>
                    </div>
                </div>

            </div>
        </div>

        <GMapMap
            :center="center"
            :zoom="14"
            style="width: 70%; height: 350px; margin: 20px 0; border-radius: 10px"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: [
                    {
                        featureType: 'poi.business',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        elementType: 'labels.icon',
                        stylers: [{ visibility: 'off' }],
                    },
                ]
            }">

            <GMapMarker :position="center" />
        </GMapMap>

        <h4 class="mt-3">Descrizione</h4>
        <p>{{cantiere?.descrizione}}</p>

        <h4 class="mt-3">Documenti</h4>
        <div v-if="_store.loadingDocumentiCantiere">Caricamento documenti</div>
        <div v-else-if="documenti && documenti.length==0">Nessun documento allegato</div>
        <div v-else>
            <div v-for="doc in documenti" :key="doc.entityId">
                <table style="width: 100%; margin-bottom: 10px;">
                    <tr style="border-bottom: 1px solid gainsboro">
                        <td>
                            <span>{{doc.documentFileName}}</span>
                        </td>
                        <td style="text-align: end">
                            <Button label="Elimina" class="p-button-text p-button-danger p-button-sm" @click="deleteDocumento(doc.documentId)" />
                            <Button label="Download" class="p-button-sm p-button-link" style="margin-left: 10px;" @click="downloadDocumento(doc.documentId, doc.documentFileName)" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <Button icon="pi pi-plus" class="p-button-sm btn-primary" label="Carica documento" @click="dialogUploadVisibile=true" />
            <!-- refectory necessario per dialog -->
            <Dialog v-model:visible="dialogUploadVisibile" style="width: 50%;" :modal="true" >
                <template #header>
                    <h1>Carica documento</h1>
                </template>
                <ProgressSpinner v-if="_store.loadingDocumentiCantiere" />
                <div v-else>
                    <FileUpload :customUpload="true" :fileLimit="1" @uploader="uploadFile"
                                :chooseLabel="'Scegli'"
                                :uploadLabel="'Importa'"
                                :cancelLabel="'Annulla'"
                                >
                        <template #empty>
                            <p>Trascina qui il file da caricare</p>
                        </template>
                    </FileUpload>
                    </div>
            </Dialog>
        </div>
    </Page>
</template>

<script setup lang="ts">
//IMPORTS VUE
import { onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { FileUploadUploaderEvent } from 'primevue/fileupload';

//IMPORT EXT
import { useFilters } from "@k-digitale/kappa";

//IMPORT MODELS
import { Cantiere } from "@/services/cesf/models";

//IMPORT MODELS
import { useBuildingSiteStore } from "../store";

//EXT
const { toDateTimeString, toDateString } = useFilters();

//STORES
const _store = useBuildingSiteStore();

//COMPOSABLES
const route = useRoute();

//REFS
const cantiere = ref<Cantiere>(null);
const idCantiere = ref<string>(route.params.id as string);

const documenti = ref<any[]>([]);
const dialogUploadVisibile = ref(false);

//VUE
onMounted(async () => {
    await loadCantiere();
    await loadDocumentiCantiere();
});

const loadCantiere = async () => {
    cantiere.value = await _store.getCantiereById(idCantiere.value);
}

const loadDocumentiCantiere = async () => {
    documenti.value = await _store.getDocumentiCantiere(idCantiere.value);
}

const downloadDocumento = async (id: number, fileName:string) => {
    const result = await _store.downloadDocumentoCantiere(idCantiere.value, id, fileName);
}

const deleteDocumento = async (id: number) => {
    const result = await _store.deleteDocumentoCantiere(idCantiere.value, id);
    if(result){
        await loadDocumentiCantiere();
    }
}

const uploadFile = async (event: FileUploadUploaderEvent) => {
    var file = event.files as File[];

    if (!file) {
        // toast.add({
        //     severity: "error",
        //     summary: "Nessun file selezionato!",
        //     life: 3000,
        // });
    }

    try {
        var formData = new FormData();
        formData.append("file", file[0]);

        // emit("file-loaded", formData);

        const result = await _store.uploadDocumentoCantiere(idCantiere.value, formData);
        if(result){
            //toast.add({severity:'success', summary:'Salvato', detail:"Documento salvato con successo.", life: 3000});
            await loadDocumentiCantiere();
        } else{
            //toast.add({severity:'error', summary:'Errore', detail:"E' avvenuto un errore durante il salvataggio del documento.", life: 3000});
        }
        dialogUploadVisibile.value = false;

    } catch (err) {
        console.log(err);
        // toast.add({
        //     severity: "error",
        //     summary: "Errore",
        //     detail: "Errore durante l'importazione del file delle anagrafiche delle Aziende di Info Camere.",
        //     life: 3000,
        // });
    }
    finally {
        // loading.value = false;
    }
};

const center = computed(() => {
    let lat = 0.0;
    let lng = 0.0;

    if(cantiere.value) {
        lat = cantiere.value.coordY;
        lng = cantiere.value.coordX;
    }
    const pos = {
        lat: lat,
        lng: lng
    };

    return pos;
});
</script>

<style lang="scss">
.tabview-custom {
    i,
    span {
        vertical-align: middle;
    }

    span {
        margin: 0 0.5rem;
    }
}
.p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.image{
    opacity: 1;
}
</style>
