<template>
    <Page>
        <KBackButton />
        <PageHeader :title="'Circolare '"></PageHeader>

        <h1>{{circolare && circolare.name}}</h1>
        <h2>WORK IN PROGRESS</h2>
    </Page>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { useCesfCmsStore } from "@/store";

const cesfCmsStore = useCesfCmsStore();
const route = useRoute();

const idCircolare = ref<string>(route.params.id as string);
const circolare = computed(() => cesfCmsStore.circolari && cesfCmsStore.circolari.find(c => c.id == idCircolare.value));

</script>


