import { RouteRecordRaw } from "vue-router";

import Employee from "./pages/Employee.vue";
import EmployeeDetail from "./pages/EmployeeDetail.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/employee",
        component: Employee,
        meta: {
            requiresAuth: true,
            menu: {
                label: "Dipendenti",
                iconAsset: "icons/ico_dipendenti.svg"
            }
        }
    },
    {
        path: "/employee/detail/:id",
        component: EmployeeDetail,
        meta: {
            requiresAuth: true,
            menu: {
                label: "Dipendenti",
                iconAsset: "icons/ico_dipendenti.svg",
                hidden: true
            }
        }
    }
];

export default routes;
