<template>
    <Page>
        <PageHeader title="Dipendenti">
            <template #title-end>
                <button @click="isNewEmployeePanelVisible = true" class="btn btn-icon btn-me">
                    <KIcon icon="it-plus" class="icon-primary"></KIcon>
                    <span class="text-uppercase fw-bold ml-2 text-primary">NUOVO DIPENDENTE</span>
                </button>
            </template>
        </PageHeader>
        <NuovoDipendente @new-worker-saved="loadCompanyWorkers(1)" v-model="isNewEmployeePanelVisible"/>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 d-flex d-inline-flex">
                <div class="form-group col-12 m-0">
                    <div class="input-group">
                        <KInputText v-model="dipFullTextSearch" :labelText="'Cerca dipendente '" :class="''" :formGroupClass="'col-12'" >
                            <template #append-container>
                                <button type="button" class="btn btn-secondary btn-icon" @click="onDipendenteSearch">
                                    <KIcon class="icon-white" icon="it-search" />
                                </button>
                            </template>
                        </KInputText>
                    </div>
                </div>
            </div>
            <div class="me-auto col-12 col-md-6 col-lg-6 d-inline-flex align-items-center justify-content-end">
                <label>Filtra </label>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary btn-outline-secondary dropdown-toggle text-black" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Titolo
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <DataTable :value="items" dataKey="id" responsiveLayout="scroll" :loading="loading">
                    <Column field="lastName" header="COGNOME"></Column>
                    <Column field="name" header="NOME"></Column>
                    <Column field="qualification" header="TITOLO"></Column>
                    <Column field="attestati" header="ATTESTATI"></Column>
                    <Column header="CORSI SCADUTI">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.corsiScaduti > 0">
                                <div class="bg-danger state-code-label inline-block mr-3" />
                                <div v-if="slotProps.data.corsiScaduti == 1" class="accordion-body p-0 inline-block">
                                    (1) Corso scaduto
                                </div>
                                <div v-else class="accordion-body p-0 inline-block">
                                    ({{slotProps.data.corsiScaduti}}) Corsi scaduti
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column >
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <button type="button" class="btn btn-primary"
                                    @click="goToWorkerDetail(slotProps.data)"> DETTAGLI </button>
                            </div>
                        </template>
                    </Column>

                    <template #empty> Nessun dipendente trovato </template>
                    <template #footer>
                        <KPaginator
                            :currentPage="currentPage"
                            @page-selected="onPageChange"
                            :totalPages="totalPages"/>
                    </template>
                </DataTable>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useFilters } from "@k-digitale/kappa";
import { useRouter, useRoute } from "vue-router";
import { employeeService } from "../service";
import { ApiPagedResult } from "@k-digitale/kappa";
import { Worker, WorkerFinder } from "@/learning/registries/models";
import { useCesfUser, useViewStatus } from "@/composables";
import { useCourseStore } from "@/modules/course/store"
import { CourseParticipationOutcomeFinder, CourseParticipationOutcomeStatus, CourseParticipationOutcomeValidityStatus } from "@/learning/instances/models";
import dayjs from "dayjs";

//IMPORT COMPONENTS
import NuovoDipendente from "@/components/NuovoDipendente.vue";

const router = useRouter();
const route = useRoute();

const isNewEmployeePanelVisible = ref<boolean>(false);

const courseStore = useCourseStore();

const { toDateTimeString } = useFilters();
const { cesfUser, isUserCompany } = useCesfUser();
const viewStatusHelper = useViewStatus();
const items = ref<any[]>([]);

const loading = ref(false);
const rows = ref(10);
const dipFullTextSearch = ref<string>("");

const totalPages = computed(() => {
    return Math.ceil(totalCount.value / finder.value.pageSize);
})

const finder = ref<WorkerFinder>({
    pageIndex: 0,
    pageSize: 10
} as WorkerFinder);
const totalCount = ref<number>();
const currentPage = ref<number>(1);

const goToWorkerDetail = (item: Worker) => {
    router.push({
        path: `/employee/detail/${item.id}`
    });
};

onMounted(async () => {
    await loadCompanyWorkers(1);
});

const loadCompanyWorkers = async (page: number, fullTextSearch?: string) => {
        loading.value = true;

        if(isUserCompany.value){

            const companyOutcomesFinder = {
                pageIndex: -1,
                pageSize: -1,
                status: CourseParticipationOutcomeStatus.Success,
                validityStatus: CourseParticipationOutcomeValidityStatus.Active,
                queryRegistriesRelations: true,
            } as CourseParticipationOutcomeFinder;
            const courseCompanyOutcomes = await courseStore.getCompanyCourseOutcomesByFinder(cesfUser.value.companyId, companyOutcomesFinder);

            finder.value.companyId = cesfUser.value.companyId;
            finder.value.pageIndex = page;
            finder.value.globalSearchTearm = fullTextSearch;

            const response = await employeeService.getWorkers(finder.value);
            totalCount.value = response.totalCount;
            currentPage.value = page;
            items.value = response.items.map(i => {
                    return {
                        id: i.id,
                        name: i.name,
                        lastName: i.lastName,
                        attestati: courseCompanyOutcomes.filter(cco => cco.courseParticipation.student.workerId == i.id).length,
                        qualification: viewStatusHelper.formatWorkerQualification(i.qualification),
                        corsiScaduti: courseCompanyOutcomes.filter(cco => cco.doesExpire &&
                            cco.courseParticipation.student.workerId == i.id &&
                            dayjs(cco.expiryDate) < dayjs()).length
                    }});

    }

    loading.value = false;
};

const onPageChange = (newPage: number) => {
    loadCompanyWorkers(newPage);
};

const onDipendenteSearch = () => {
    loadCompanyWorkers(1, dipFullTextSearch.value);
};
</script>

<style lang="scss">
.tb-lista-cantieri .p-datatable-wrapper {
    table {
        thead {
            tr {
                th {
                    border: none;
                }
            }
        }
    }
}

.code-employee-state {
    top: 5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.course-expired-employee-state {
    top: 5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.employees-paging-info {
    opacity: 1;
    color: rgba(92,111,130,1);
    font-family: "TitilliumWeb-Regular";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
</style>
