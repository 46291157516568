import { RouteRecordRaw } from "vue-router";

import Communication from './pages/Communication.vue';
import Content from './pages/Content.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/communication',
        component: Communication,
        meta: { 
            requiresAuth: true,
            menu: {
                label: 'Circolari e comunicazioni',
                iconAsset: 'icons/ico_circolari.svg'
            }
        }
    },
    {
        path: "/communication/detail/:id",
        component: Content,
        meta: {
            requiresAuth: true,
        }
    }
];

export default routes;