import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { CourseInstance, CourseInstanceFinder, LessonInstance, LessonInstanceFinder } from "@/learning/instances/models";
import { SaveCourseParticipationRequestListItemRequest } from "./requests";

class ModuleCourseService extends BaseService {

    async getStartingCourses(status: string, queryRelations: boolean, startAfterDate: Date) : Promise<ApiPagedResult<CourseInstance>> {
        const params = {
            status: status,
            queryRelations: queryRelations,
            startAfterDate: startAfterDate,
            hideOldCourses: true,
            pageIndex: 0,
            pageSize: 3
        } as CourseInstanceFinder;

        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<CourseInstance>>(`learning/course-instances`, { params: params })
        );

        return response!.data;
    }

    async getCourse(id: string) : Promise<CourseInstance> {
        const response = await this.tryExecute(
            this.api.get<CourseInstance>(`learning/course-instances/${id}`)
        );

        return response!.data;
    }

    async getLessons(finder: LessonInstanceFinder) : Promise<ApiPagedResult<LessonInstance>> {
        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<LessonInstance>>(`learning/instances/lessons`, { params: finder })
        );

        return response!.data;
    }
}

export const courseService = new ModuleCourseService();