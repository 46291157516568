import { computed, ref, watch } from 'vue';
import { useUserStore } from '@k-digitale/kappa'
import { CesfUserInfoResponse } from '@/models/Account/models';

export function useCesfUser() {
    const cesfUser = ref<CesfUserInfoResponse>(null);
    const { user } = useUserStore();
    watch(() => user, async () => {
        cesfUser.value = user as any;
    }, {immediate: true});
    const isUserCompany = computed(() => {
        return !!cesfUser.value?.companyId;
    })

    const isUserWorker = computed(() => {
        return !!cesfUser.value?.workerId;
    })
    return { cesfUser, isUserWorker, isUserCompany }
}