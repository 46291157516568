<template>
    <Page>
        <PageHeader title="Certificazioni"></PageHeader>
    </Page>
</template>

<script setup lang="ts">

</script>

<style lang="scss">

</style>