<template>
    <Page :loading="loading">
        <PageHeader :title="employeeFullName"></PageHeader>

        <div class="row mx-0" v-if="employee">
            <div class="col-12">
                <div class="row d-flex align-items-center mt-3 bg-light py-3">
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Data Assunzione</h6>
                        <h5>{{ toDateString(employee.hiringDate) }}</h5>
                    </div>
                    <div class="col-12 col-md-5 col-lg-6">
                        <h6 class="">Azienda</h6>
                        <h5>{{ employee.company ? employee.company.businessName : "" }}</h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-2">
                        <h6 class="">Attestati</h6>
                        <h5>{{ participationsNumber }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4" v-if="employee">
            <div class="col-12 ">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link px-5 active" id="Anagrafica-tab" data-bs-toggle="tab" href="#Anagrafica" role="tab" aria-controls="Anagrafica" aria-selected="true">Anagrafica</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link px-5" id="Attestati-tab" data-bs-toggle="tab" href="#Attestati" role="tab" aria-controls="Attestati" aria-selected="false">Attestati</a>
                    </li>

                </ul>
                <div class="tab-content" id="myTabContent" >
                    <div  class="tab-pane py-4 fade show active" id="Anagrafica" role="tabpanel" aria-labelledby="Anagrafica-tab">
                        <div v-if="isRegistryInEditMode" class="row px-3 py-3 border-1 mt-3">
                            <form
                                id="formEditRegistryData"
                                class="needs-validation"
                                autocomplete="off"
                            >
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                                        <h3 class="m-0">Modifica dati anagrafica</h3>
                                        <a @click="stopEditRegistryData" class="btn btn-xs btn-icon p-0" role="button">
                                            <KIcon class="icon-primary" icon="it-close-big" />
                                            <span class="ml-4 text-primary">ANNULLA</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-5">
                                        <KInputText id="workerName" v-model="workerToSave.name" labelText="Nome *"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-5">
                                        <KInputText id="workerLastName" v-model="workerToSave.lastName" labelText="Cognome *"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-2">
                                        <KSelect v-model="workerToSave.sex" :options="workerSexDropdownOptions" labelText="Sesso"></KSelect>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KInputText id="workerFiscalCode" v-model="workerToSave.fiscalCode" labelText="Codice Fiscale *"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KDatePicker inputId="workerBirthDate" :max-date="new Date()" class="w-full" v-model="workerToSave.birthdate" labelText="Data di nascita *" />
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KInputText id="workerBirthPlace" v-model="workerToSave.birthplace" labelText="Luogo di nascita *"></KInputText>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KDatePicker class="w-full" v-model="workerToSave.hiringDate" labelText="Data assunzione" />
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KSelect v-model="workerToSave.qualification" :options="workerQualificationDropDownOptions" labelText="Qualifica"></KSelect>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KSelect v-model="workerToSave.status" :options="workerStatusDropDownOptions" labelText="Status"></KSelect>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button @click="saveWorkerRegistryData" class="btn btn-primary mt-2" type="submit">SALVA</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else class="row px-3 py-3 border-1 mt-3">
                            <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                                <h3 class="m-0">Anagrafica</h3>

                                <a @click="startEditRegistryData" class="btn btn-xs btn-icon p-0" role="button">
                                    <KIcon class="icon-primary" icon="it-pencil" />
                                    <span class="ml-4 text-primary">MODIFICA</span>
                                </a>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Cognome</h6>
                                <h5>{{ employee.lastName }}</h5>
                            </div>
                            <!-- <div class="col-12 col-md-5">
                                <KInputText :id="'lastName'" :labelText="'Cognome'" v-model:value="employee.worker.lastName"></KInputText>
                            </div> -->
                            <div class="col-12 col-md-5">
                                <h6 class="">Nome</h6>
                                <h5>{{ employee.name }}</h5>
                            </div>
                            <div class="col-12 col-md-12">
                                <h6 class="">Tipologia Documento</h6>
                                <h5>Codice Fiscale - {{ employee.fiscalCode }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Data di nascita</h6>
                                <h5>{{ toDateString(employee.birthdate) }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Luogo di nascita</h6>
                                <h5>{{ employee.birthplace }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Cittadinanza</h6>
                                <h5>{{ employee.citizenship }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Qualifica</h6>
                                <h5>{{ viewStatusHelper.formatWorkerQualification(employee.qualification) }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Status</h6>
                                <h5>{{ viewStatusHelper.formatWorkerStatus(employee.status) }}</h5>
                            </div>
                        </div>

                        <div v-if="isRegistryContactsInEditMode" class="row px-3 py-3 border-1 mt-3">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                                    <h3 class="m-0">Modifica contatti</h3>
                                    <a @click="stopEditRegistryContacts" class="btn btn-xs btn-icon p-0" role="button">
                                        <KIcon class="icon-primary" icon="it-close-big" />
                                        <span class="ml-4 text-primary">ANNULLA</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.address" labelText="Indirizzo"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.city" labelText="Comune"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-2">
                                    <KInputText v-model="workerToSave.zipCode" labelText="CAP"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-2">
                                    <KInputText v-model="workerToSave.provincia" labelText="Provincia"></KInputText>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.email" labelText="E-mail"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.telephone" labelText="Telefono"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.cellPhone" labelText="Cellulare"></KInputText>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button @click="saveWorkerContacts" class="btn btn-primary mt-2">SALVA</button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row px-3 py-3 border-1 mt-3">
                            <div class="col-12 d-flex justify-content-between mb-3">
                                <h3 class="m-0">Contatti</h3>

                                <a @click="startEditRegistryContacts" class="btn btn-xs btn-icon p-0" role="button">
                                    <KIcon class="icon-primary" icon="it-pencil" />
                                    <span class="ml-4 text-primary">MODIFICA</span>
                                </a>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Indirizzo</h6>
                                <h5>{{ employee.address }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Cap</h6>
                                <h5>{{ employee.zipCode }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Comune</h6>
                                <h5>{{ employee.city }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Provincia</h6>
                                <h5>{{ employee.provincia }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Telefono</h6>
                                <h5>{{ employee.telephone }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Cellulare</h6>
                                <h5>{{ employee.cellPhone }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Email</h6>
                                <h5>{{ employee.email }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane py-4 fade" id="Attestati" role="tabpanel" aria-labelledby="Attestati-tab">
                        <DataTable :value="participationsTableData" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column field="corso" header="CORSO"></Column>
                            <Column field="ore" header="ORE"></Column>
                            <Column header="DATA SCADENZA">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataScadenza)}}
                                </template>
                            </Column>
                            <Column header="STATO">
                                <template #body="slotProps">
                                    <div class="state-code-label inline-block mr-3" :class="`bg-${slotProps.data?.statusCode ?? 'primary'}`" />
                                    <div class="accordion-body p-0 inline-block">
                                        {{ slotProps.data.statusDescription }}
                                    </div>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-primary"> ATTESTATO </button>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessun attestato trovato </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">

//IMPORTS GENERAL
import { onMounted } from "@vue/runtime-core";
import { computed, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";

//IMPORTS MODELS
import { Worker, Student, StudentFinder } from "@/learning/registries/models";
import { instancesService } from "@/learning/instances/instancesService";
import { registriesService } from "@/learning/registries/registriesService";
import { employeeService } from "../service";
import { CourseParticipationOutcome, CourseParticipationOutcomeFinder, CourseParticipationOutcomeValidityStatus,
    CourseParticipationOutcomeStatus} from "@/learning/instances/models";
import { SaveWorkerRequest } from "@/learning/registries/Requests";
import { mapWorkerToSaveRequest } from "@/learning/registries/Mappers";

//IMPORTS KAPPA
import { useFilters } from "@k-digitale/kappa";
import { FormValidate } from "bootstrap-italia";

//IMPORTS COMPOSABLES
import { useViewStatus, useValidationHelper } from "@/composables";

//COMPOSABLES
const { toDateTimeString, toDateString } = useFilters();
const viewStatusHelper = useViewStatus();
const router = useRouter();
const route = useRoute();
const { isValidFiscalCode } = useValidationHelper();

//REFS
const idWorker = ref(route.params.id);
const loading = ref(false);
const employee = ref<Worker>(null);
const workerToSave = ref<SaveWorkerRequest>({} as SaveWorkerRequest);
const courseParticipations = ref<CourseParticipationOutcome[]>([]);
const workerSexDropdownOptions = ref<any[]>(viewStatusHelper.getWorkerSexDropdownOptions());
const workerQualificationDropDownOptions = ref<any[]>([{
        name: "Nessuna",
        value: null,
}].concat(viewStatusHelper.getWorkerQualificationDropdownOptions()));
const workerStatusDropDownOptions = ref<any[]>([{
    name: "Nessuno",
    value: null,
}].concat(viewStatusHelper.getWorkerStatusDropdownOptions()));

const isRegistryInEditMode = ref(false);
const isRegistryContactsInEditMode = ref(false);

//COMPUTED
const employeeFullName = computed(() => {
    if (employee.value) {
        return employee.value!.name + " " + employee.value!.lastName;
    }
    return "";
});

const participationsNumber = computed(() => {
    return courseParticipations.value.length;
});

const participationsTableData = computed(() => {
    return courseParticipations.value.map(cp => {
        return {
            id: cp.id,
            corso: cp.relatedCourseTemplate.title,
            ore: `${cp.relatedCourseTemplate.totalHours} ore`,
            dataScadenza: cp.expiryDate,
            statusCode: viewStatusHelper.getStatusCodeFromDate(cp.expiryDate),
            statusDescription: viewStatusHelper.getStatusLabelFromDate(cp.expiryDate)
        };
    })
});

//VUE
onMounted(async () => {
    await loadData();
});

watch(isRegistryInEditMode, async (newValue) => {
    if(newValue){
        await nextTick();
        defineValidations();
    }
});

//VARS
let validate : any;

//METHODS
const loadData = async () => {
    loading.value = true;

    employee.value = await employeeService.getWorker(idWorker.value as string);
    const student = (await (await registriesService.getStudentsByFinder({workerId: employee.value.id} as StudentFinder )).items[0]);
    const outcomeResponse = await instancesService.getCourseParticipationOutcomesByFinder({
        studentId: student.id,
        pageIndex: -1,
        pageSize: -1,
        sortField: "expiryDate",
        sortOrder: -1,
        status: CourseParticipationOutcomeStatus.Success,
        validityStatus: CourseParticipationOutcomeValidityStatus.Active,
    } as CourseParticipationOutcomeFinder);
    courseParticipations.value = outcomeResponse.items;

    loading.value = false;
};

const defineValidations = () => {
    validate = new FormValidate("#formEditRegistryData", {
    errorFieldCssClass: "is-invalid",
    errorLabelCssClass: "form-feedback",
    errorLabelStyle: "",
    focusInvalidField: true,
  });

    validate.addField("#workerName", [
        {
            rule: "required",
            errorMessage: "Il nome del dipendente è richiesto",
        },
    ]);
    validate.addField("#workerLastName", [
        {
            rule: "required",
            errorMessage: "Il cognome del dipendente è richiesto",
        },
    ]);
    validate.addField("#workerBirthDate", [
        {
            rule: "required",
            errorMessage: "La data di nascita del dipendente è richiesta",
        },
    ]);
    validate.addField("#workerBirthPlace", [
        {
            rule: "required",
            errorMessage: "Il luogo di nascita del dipendente è richiesto",
        },
    ]);
    validate.addField("#workerFiscalCode", [
        {
            rule: "required",
            errorMessage: "Il codice fiscale del dipendente è richiesto",
        },
        {
            validator: (value: string) => isValidFiscalCode(value),
            errorMessage: "Il formato del codice fiscale del dipendendente non è valido"
        }
    ]);
}

const startEditRegistryData = () => {
    workerToSave.value = mapWorkerToSaveRequest(employee.value);
    isRegistryInEditMode.value = true;
}

const stopEditRegistryData = () => {
    workerToSave.value = {} as SaveWorkerRequest;
    isRegistryInEditMode.value = false;
}

const startEditRegistryContacts = () => {
    workerToSave.value = mapWorkerToSaveRequest(employee.value);
    isRegistryContactsInEditMode.value = true;
}

const stopEditRegistryContacts = () => {
    workerToSave.value = {} as SaveWorkerRequest;
    isRegistryContactsInEditMode.value = false;
}

const saveWorkerRegistryData = async () => {
    await validate.revalidate();
    if(validate.isValid){
        loading.value = true;
        const response = await registriesService.saveWorker(workerToSave.value);
        if(response){
            stopEditRegistryData();
            stopEditRegistryContacts();
            await loadData();
        }
        loading.value = false;
    }
}
const saveWorkerContacts = async () => {
    loading.value = true;
    const response = await registriesService.saveWorker(workerToSave.value);
    if(response){
        stopEditRegistryData();
        stopEditRegistryContacts();
        await loadData();
    }
    loading.value = false;
}
</script>

<style lang="scss">
.tabview-custom {
    i,
    span {
        vertical-align: middle;
    }

    span {
        margin: 0 0.5rem;
    }
}
.p-tabview p {
    line-height: 1.5;
    margin: 0;
}
</style>
