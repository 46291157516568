<template>
    <Page>
        <PageHeaderFull title="Benvenuto" :titleUnderline="user && user.displayName">
            <template #first-column>
                Codice Cassa Edile: <span v-if="companyData.eduCompany" class="first-col-label">{{ companyData.eduCompany?.extCode1 }}</span>
            </template>
            <template #second-column>
                <div class="state-code inline-block mr-3" :class="`bg-success`"></div>
                <div class="accordion-body p-0 inline-block">
                    Regolarità Amministrativa: <span class="second-col-label">In regola</span>
                </div>
            </template>
            <template v-if="companyData?.responsabileSicurezzaLavoratori" #third-column>
                <!-- Corsi: <span class="third-col-label">{{ companyData.courses.length ? companyData.courses.join(", ") : " - " }}</span> -->
                <strong>RLS: </strong>{{companyData.responsabileSicurezzaLavoratori.eduWorker.name}} {{companyData.responsabileSicurezzaLavoratori.eduWorker.lastName}}
            </template>
        </PageHeaderFull>

        <div class="row my-4">
            <div class="col-12 p-0">
                <div class="mb-2">
                    <span class="notify-title">Notifiche</span>
                </div>
                <!-- <button value="modal" @click="testmodal"></button> -->
                Nessuna notifica presente al momento.
                <!-- <KNotificationCard
                                   :title="notifica.title"
                                   :body="notifica.body"
                                   v-for="(notifica, index) in companyData.notifiche"
                                   :key="index"
                                   :showPrimaryButton="true"
                                   :showSecondaryButton="notifica.pdfUrl && notifica.pdfUrl.length > 0"
                                   :primaryButton="{ label: 'DETTAGLI' }"
                                   :secondaryButton="{ label: 'APRI PDF' }"
                                   @on-primary-button-click="onNotificationPrimaryButtonClick(notifica.id)"
                                   @on-secondary-button-click="onNotificationSecondaryButtonClick(notifica.pdfUrl)">
                    <template #pre-info-title>
                        <span class="mr-3 uppercase notifica-data px-3">
                            {{ toDDMMMMString(notifica.data) }}
                        </span>
                    </template>
                </KNotificationCard> -->
            </div>
        </div>

        <div class="row my-4">
            <div class="col-12 p-0">

                <KTabs>
                    <KTab name="Corsi" :higlightCounter="coursesExpired">
                        <DataTable :value="corsiItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column header="CORSO">
                                <template #body="slotProps">
                                    <div v-if="isStateVisible(slotProps.data)" class="bg-primary state-code-label inline-block mr-3" />
                                    {{slotProps.data.corso}}
                                </template>
                            </Column>
                            <Column header="INIZIO CORSO">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.inizioCorso)}}
                                </template>
                            </Column>
                            <Column header="DATA SCADENZA">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataScadenza)}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-outline-primary"
                                            @click="goToCourseDetail()"> SCOPRI DI PIU' </button>
                                    </div>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-primary"
                                            @click="goToIscrizioneCorso(slotProps.data)"> ISCRIVITI </button>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessun corso trovato </template>
                        </DataTable>
                    </KTab>
                    <KTab name="Cantieri">
                        <DataTable :value="cantieriItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column field="comune" header="COMUNE" />
                            <Column field="tipologia" header="TIPOLOGIA" />
                            <Column header="INIZIO">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataInizio)}}
                                </template>
                            </Column>
                            <Column header="FINE">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataFine)}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-primary"
                                            @click="goToCantiereDetail(slotProps.data)"> DETTAGLI </button>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessun cantiere trovato </template>
                        </DataTable>
                    </KTab>
                    <KTab name="Circolari e Comunicazioni">
                        <DataTable :value="cesfCmsStore.circolari" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column field="name" header="TITOLO"></Column>
                            <Column header="DATA PUBBLICAZIONE">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.publishedDate)}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <a role="button" class="btn btn-primary" :href="(link+slotProps.data.url)"> DETTAGLI </a>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessuna circolare o comunicazione </template>
                        </DataTable>
                    </KTab>
                </KTabs>
            </div>
        </div>

        <!-- <div class="row my-4">
            <div class="col-12 p-0">
                <h3 class="vantaggi-opportunita-title">Vantaggi e opportunità</h3>
            </div>
            <div class="col-12 p-0">
                <K3ColContainer col1Class="p-3" col2Class="p-3" col3Class="p-3">
                    <template #col1>
                        <KCard :title="'Verifica documentazione aziendale sulla sicurezza Verifica documentazione aziendale sulla sicurezza Verifica documentazione aziendale sulla sicurezza Verifica documentazione aziendale sulla sicurezza'"
                                :showDetailButton="true"
                                :showCmdButton="true"
                                :labelDetailButton="'SCOPRI DI PIU'"
                                :labelCmdButton="'ISCRIVITI'"
                                :eventCmdButton="goToCourseDetail">

                        </KCard>
                    </template>
                    <template #col2>
                        <KCard :title="'Verifica documentazione aziendale sulla sicurezza'"
                                :showDetailButton="true"
                                :showCmdButton="true"
                                :labelDetailButton="'SCOPRI DI PIU'"
                                :labelCmdButton="'ISCRIVITI'"
                                :eventCmdButton="goToCourseDetail">

                        </KCard>
                    </template>
                    <template #col3>
                        <KCard :title="'Verifica documentazione aziendale sulla sicurezza'"
                                :showDetailButton="true"
                                :showCmdButton="true"
                                :labelDetailButton="'SCOPRI DI PIU'"
                                :labelCmdButton="'ISCRIVITI'"
                                :eventCmdButton="goToCourseDetail">

                        </KCard>
                    </template>
                </K3ColContainer>
            </div>
        </div> -->

        <BannerEsperto />

        <div class="row my-4">
            <div class="col-8">
                <div class="col-12 p-0">
                    <h1 class="vantaggi-opportunita-title">Ultime dal settore</h1>
                </div>
                <div class="col-12 p-0">
                    <K2ColContainer :col1Class="'p-3'" :col2Class="'p-3'">
                        <template v-if="news1" #col1>
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="category-top">
                                            <a class="category text-uppercase" href="#">{{news1.title}}</a>
                                        </div>
                                        <h5 class="card-title ">{{news1.description}}</h5>
                                        <a class="read-more" :href="news1.url" role="button" :title="news1.title">
                                            <span class="text">SCOPRI DI PIU'
                                                <span class="visually-hidden">Verifica documentazione aziendale sulla sicurezza</span>
                                            </span>
                                            <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="news2" #col2>
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="category-top">
                                            <a class="category text-uppercase" href="#">{{news2.title}}</a>
                                        </div>
                                        <h5 class="card-title ">{{news2.description}}</h5>
                                        <a :href="news2.url" class="read-more" role="button" :title="'Verifica documentazione aziendale sulla sicurezza'">
                                            <span class="text">SCOPRI DI PIU'
                                                <span class="visually-hidden">Verifica documentazione aziendale sulla sicurezza</span>
                                            </span>
                                            <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </K2ColContainer>
                </div>
            </div>
            <div class="col-4">
                <div class="col-12 p-0">
                    <h1 class="vantaggi-opportunita-title">WebTV</h1>
                </div>
                <div class="col-12 p-0">
                    <KCarousel></KCarousel>
                </div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useFilters, useUserStore, cmsService } from "@k-digitale/kappa";

import { useCesfUser, useViewStatus } from "@/composables";
import { CompanyExtension } from "@/services/learningExtensions/models";
import { learningExtensionService } from "@/services/learningExtensions/LearningExtensionsServices";
import { Cantiere, CantiereFinder } from "@/services/cesf/models";

//IMPORT STORES
import { useBuildingSiteStore } from "@/modules/buildingsite/store";
import { useCourseStore } from "@/modules/course/store";
import { useCesfCmsStore } from "@/store"
import { CourseInstanceFinder, CourseParticipationOutcomeFinder, CourseParticipationOutcomeStatus, CourseParticipationOutcomeValidityStatus } from "@/learning/instances/models";

//IMPORT COMPONENTS
import BannerEsperto from "@/components/BannerEsperto.vue";

//COMPOSABLES
const { isUserCompany, cesfUser } = useCesfUser();
const viewStatusHelper = useViewStatus();

const router = useRouter();
const { toDDMMMMString, toDateString } = useFilters();
const { user } = useUserStore();

//STORES
const cesfCmsStore = useCesfCmsStore();
const coursesStore = useCourseStore();

//REFS
const loading = ref(true);
const corsiItems = ref<any[]>([]);


//COMPUTED
const link = computed(() => import.meta.env.VITE_CMS_DETAIL_BASE_URL);
const newsByPublishDate = computed(() => {
    return cesfCmsStore.news.sort((a,b) => {
        return new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime();
    });
})
const news1 = computed(() => {
    if(newsByPublishDate.value.length < 1){
        return null;
    }
    const news = newsByPublishDate.value[0];
    return {
        title: news.name,
        description: news.contents.find(c => c.code == "text")?.value.value,
        url: import.meta.env.VITE_CMS_DETAIL_BASE_URL+news.url,
    };
});
const news2 = computed(() => {
    if(newsByPublishDate.value.length < 2){
        return null;
    }
    const news = newsByPublishDate.value[1];
    return {
        title: news.name,
        description: news.contents.find(c => c.code == "text")?.value.value,
        url: import.meta.env.VITE_CMS_DETAIL_BASE_URL+news.url,
    };
});

const coursesExpired = computed(() => {
    return corsiItems.value.filter(function (x: any) {
        return viewStatusHelper.isExpiredOrInExpiring(x.dataScadenza);
    }).length;
});

//VUE
onMounted(async () => {

    if(isUserCompany.value){
        loading.value = true;

        // TODO: chiamate parallele
        companyData.value = await learningExtensionService.getCompanyExtByEduCompanyId(cesfUser.value.companyId);
        await loadCantieri();
        await loadCorsiItems();
        await cesfCmsStore.getCircolari();
        await cesfCmsStore.getNews();

        loading.value = false;
    }

});

const buildingSiteStore = useBuildingSiteStore();
const cantieri = ref<Cantiere[]>([]);
const loadCantieri = async (pageNumber : number = 1) => {
    const finder = {
        pageIndex: pageNumber,
        pageSize: 10
    } as CantiereFinder;
    if(isUserCompany.value){
        const result = await buildingSiteStore.getCantieriByCompany(finder, cesfUser.value.companyId);
        cantieri.value = result.items;
    }
}

const loadCorsiItems = async (pageNumber : number = 1) => {
    const startingCoursesFinder = {
        pageIndex: 1,
        pageSize: 10,
        queryRelations: true,
    } as CourseInstanceFinder;
    const startingCourses = await coursesStore.getStartingCourses(startingCoursesFinder);

    const companyOutcomesFinder = {
        pageIndex: -1,
        pageSize: -1,
        status: CourseParticipationOutcomeStatus.Success,
        validityStatus: CourseParticipationOutcomeValidityStatus.Active,
    } as CourseParticipationOutcomeFinder;
    const courseCompanyOutcomes = await coursesStore.getCompanyCourseOutcomesByFinder(cesfUser.value.companyId, companyOutcomesFinder);
    corsiItems.value = startingCourses.map(sc => {
        return {
            id: sc.id,
            corso: sc.template.title,
            inizioCorso: sc.startDate,
            dataScadenza: courseCompanyOutcomes
                .filter(co => co.relatedCourseTemplateId == sc.courseTemplateId)
                .reduce((prev, curr) => {
                    if(!curr.doesExpire) return curr;
                    return curr.expiryDate < prev.expiryDate ? curr : prev;
                }).expiryDate,
        }
    });
}

const cantieriItems = computed(() =>
    cantieri.value.map(c => {
        return {
            id: c.id,
            comune: `${c.comuneISTAT} (${c.provinciaISTAT})`,
            tipologia: c.tipologiaDeiLavori,
            dataInizio: c.dataPresuntoInizio,
            dataFine: c.dataPresuntaFine
        };
    })
);
const goToCantiereDetail = (item: Cantiere) => {
    router.push({
        path: `/buildingsite/detail/${item.id}`
    });
};

const goToCircolareDetail = (item: any) => {
    router.push({
        path: `/communication/detail/${item.id}`
    });
};

const isStateVisible = (item: any) => {
    return viewStatusHelper.isExpiredOrInExpiring(item.dataScadenza);
};

const goToIscrizioneCorso = (item: any) => {
    // router.push({
    //     path: `/employee/detail/${item.id}`
    // });
};

const goToCourseDetail = () => {
    router.push({
        path: `/course/registration`,
    });
};

const companyData = ref<CompanyExtension>({} as CompanyExtension);

//METHODS

const onNotificationPrimaryButtonClick: any = function (id: number) {
    console.log(id);
};

const onNotificationSecondaryButtonClick: any = function (url: string) {
    console.log(url);
};

</script>

<style scoped lang="scss">
.vantaggi-opportunita-title {
    color: #1c2024;
}
.first-col-label {
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 0px 5px;
}
.third-col-label {
    color: #0066cc;
}
.second-col-label {
    color: #00ba07;
}
.state-code {
    background-color: #00ba07 !important;
    width: 14px;
    height: 14px;
    border-radius: 8px;
}
.notify-title {
    font-size: 28px;
}

.notifica-data {
    color: #757575;
    font-size: 14px;
    background-color: #f8f8f8;
    border-radius: 4px;
}
.tabview-custom {
    i,
    span {
        vertical-align: middle;
    }

    span {
        margin: 0 0.5rem;
    }
}

.p-tabview p {
    line-height: 1.5;
    margin: 0;
}
.state-code-label {
    width: 12px;
    height: 12px;
    border-radius: 6px;
}
.tb-lista-corsi-home,
.tb-lista-cantieri-home {
    .p-datatable-wrapper {
        table {
            tbody {
                tr {
                    td:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
