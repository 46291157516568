<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router"
import { useLayouts, useUserStore } from "@k-digitale/kappa";
//IMPORT COMPOSABLES
import { useViewStatus } from "@/composables"

const route = useRoute();
const router = useRouter();
const { appStarting, layouts } = useLayouts();

//COMPOSABLES
const { updateLocale } = useViewStatus();

const layout = computed(() => appStarting.value === true ? layouts['splash'] : layouts[route?.meta.layout as string || 'default'] )
const userStore = useUserStore();

onMounted(async () => {
    appStarting.value = true;
    updateLocale();
    try {
        await userStore.initUser();
    }
    finally {
        appStarting.value = false;
    }
})

watch(
    () => userStore.isLogged,
    (newValue, oldValue) => {
        if(oldValue === true && newValue === false) {
            router.push('/login');
        }
    }
);

</script>

<template>
    <ConfirmDialog></ConfirmDialog>
    <Toast></Toast>

    <component :is="layout" />
    <!-- <transition name="fade" mode="out-in">
        <component :is="layout" />
    </transition> -->
</template>

<style>
.p-toast.p-toast-topright {
    z-index: 1000;
    top: 70px;
}

/* .fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
} */

</style>