<template>
    <Page v-if="corso != null">
        <div class="row">
            <div class="col-12">
                <div class="c-line kd-header-full">
                    <div class="header-top-title">Corso</div>
                    <div class="row">
                        <div class="col-12 col-md-8 col-lg-12">
                            <h3 class="title-text">
                                {{ corso.template.title }}
                            </h3>
                        </div>
                    </div>
                    <K3ColContainer rowClass="m-0" col1Class="p-1" col2Class="p-1" col3Class="p-1">
                        <template #col1>
                            <p class="header-sub-detail m-0">Durata</p>
                            <p>{{ corso.template.totalHours }} ore</p>
                        </template>
                        <template #col2>
                            <p class="header-sub-detail m-0">Partenza corso</p>
                            <p>{{ toDateString(corso.startDate) }}</p>
                        </template>
                        <template #col3>
                            <p class="header-sub-detail m-0">Luogo</p>
                            <p>{{ placeOfCourse }}</p>
                        </template>
                    </K3ColContainer>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 p-0">
                <div class="" id="collapseExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="dati-utente">
                            <button
                                    class="accordion-button h4 fw-normal text-decoration-none border-none border-bottom"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-user-data"
                                    aria-expanded="true"
                                    aria-controls="collapse1c">
                                Verifica i tuoi dati
                            </button>
                        </h2>
                        <div id="collapse-user-data" class="accordion-collapse collapse show" role="region" aria-labelledby="dati-utente">
                            <div class="accordion-body mt-5">
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.extCode2" :labelText="'Codice cassa edile '" :class="'form-control-plaintext'" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.businessName" :labelText="'Ragione Sociale'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.partitaIVA" :labelText="'Partita IVA'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.fiscalCode" :labelText="'Codice Fiscale'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.address" :labelText="'Indirizzo'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.zipCode" :labelText="'CAP'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.city" :labelText="'Comune'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.provincia" :labelText="'Provincia'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.phone" :labelText="'Telefono'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.fax" :labelText="'Fax'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.cellPhone" :labelText="'Cellulare'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.email" :labelText="'Email'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4 class="fw-normal">Chi desideri iscrivere?</h4>
            </div>
            <div class="row my-4">
                <div class="col-12 p-0">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active px-5" id="cercaDipendente-tab" data-bs-toggle="tab" href="#cercaDipendente" role="tab" aria-controls="cercaDipendente" aria-selected="true">Cerca Dipendente</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link px-5 disabled" id="caricaFile-tab" data-bs-toggle="tab" href="#caricaFile" role="tab" aria-controls="caricaFile" aria-selected="false" aria-disabled="true">Carica File</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link px-5 disabled" id="ricercaAvanzata-tab" data-bs-toggle="tab" href="#ricercaAvanzata" role="tab" aria-controls="Ricerca Avanzata" aria-selected="false" aria-disabled="true">Ricerca Avanzata</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane py-4 fade show active" id="cercaDipendente" role="tabpanel" aria-labelledby="cercaDipendente-tab">
                            <ul class="list-group">
                                <li class="list-group-item p-2">
                                    <div class="form-group m-0">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text border-none">
                                                    <KIcon icon="it-search" />
                                                </div>
                                            </div>

                                            <input type="text" class="form-control border-none" placeholder="Cerca dipendente" @input="onSearchEmployee" />
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item px-3 py-0" v-for="(dip, index) in dipendentiCercatiItems" :key="index">
                                    <div :class="{'row': true,  'align-items-center':true,  'kd-already-sub':isAlreadySubscribed(dip)}">
                                        <div class="col-12 col-xs-12 col-md-4 col-lg-3 list-row-dipendente-name">{{ dip.lastName }} {{ dip.name }}</div>
                                        <div class="col-12 col-xs-12 col-md-4 col-lg-5 list-row-dipendente-cf">{{ dip.fiscalCode }}</div>
                                        <div class="col-12 col-xs-12 col-md-4 col-lg-4 text-md-end">
                                            <button v-if="!isAlreadySubscribed(dip)" type="button" class="btn btn-add-dipendente" @click="onAddEmployeeTosubscribe(dip, index)">
                                                <span class="text-uppercase fw-bold ml-2 text-primary">AGGIUNGI</span>
                                            </button>
                                            <button v-else disabled type="button" class="btn btn-add-dipendente" @click="onAddEmployeeTosubscribe(dip, index)">DIPENDENTE GIA' ISCRITTO</button>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <button @click="isNewEmployeePanelVisible = true" class="btn btn-icon btn-me">
                                        <KIcon icon="it-plus" class="icon-primary"></KIcon>
                                        <span class="text-uppercase fw-bold ml-2 text-primary">NUOVO DIPENDENTE</span>
                                    </button>
                                </li>
                            </ul>
                            <NuovoDipendente @new-worker-saved="loadData" v-model="isNewEmployeePanelVisible"/>
                            <div class="row">
                                <div class="col-12">
                                    <DataTable :value="dipendentiAggiuntiItems" dataKey="id" responsiveLayout="scroll">
                                        <Column field="lastName" header="COGNOME"></Column>
                                        <Column field="name" header="NOME"></Column>
                                        <Column field="fiscalCode" header="CODICE FISCALE"></Column>

                                        <template #empty> Nessun dipendente aggiunto </template>
                                        <template #footer>
                                            <div class="text-end">Totale: {{ dipendentiAggiuntiItems.length }} iscritti</div>
                                        </template>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane py-4 fade" id="caricaFile" role="tabpanel" aria-labelledby="caricaFile-tab">carica file</div>
                        <div class="tab-pane py-4 fade" id="ricercaAvanzata" role="tabpanel" aria-labelledby="ricercaAvanzata-tab">Work in progress - ricerca avanzata</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div class="col-12">
                <button type="button" @click="sendSubscriptionRequest" class="btn btn-primary col-xs-8 col-md-4 col-lg-4 col-xl-3">INVIA RICHIESTA</button>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useFilters, SortingOrder } from "@k-digitale/kappa";
import { courseService } from "../service";
import { CourseInstance, CourseParticipationRequest, CourseParticipationRequestFinder, LessonInstance, LessonInstanceFinder,} from "@/learning/instances/models";
import { useRoute, useRouter } from "vue-router";
import { profileService } from "@/modules/profile/service";
import { Company } from "@/learning/registries/models";
import { employeeService } from "@/modules/employee/service";
import { instancesService } from "@/learning/instances/instancesService";
import { useCesfUser } from "@/composables";
import { useCourseStore } from "../store";

import { Student, StudentFinder, Worker, WorkerFinder } from "@/learning/registries/models";
import { SaveCourseParticipationRequestListItemRequest } from "../requests";
import { SaveStudentRequest } from "@/learning/registries/Requests";

//IMPORT COMPONENTS
import NuovoDipendente from "@/components/NuovoDipendente.vue";

const router = useRouter();
const route = useRoute();
const courseStore = useCourseStore();

const { toDateString } = useFilters();

const idCorso = ref(route.params.id);
const corso = ref<CourseInstance>(null);
const company = ref<Company>({} as Company);
const lessons = ref<LessonInstance[]>([]);
const { cesfUser, isUserCompany } = useCesfUser();

const dipendentiAggiuntiCoilumns = ref([
    {
        field: "name",
        header: "NOME",
    },
    {
        field: "lastName",
        header: "COGNOME",
    },
    {
        field: "fiscalCode",
        header: "CODICE FISCALE",
    },
    {
        field: "",
        header: "QUALIFICA",
    },
]);

const companyStudents = ref<Student[]>([]);
const companyParticipationRequests = ref<CourseParticipationRequest[]>([]);
const dipendentiCercatiItems = ref<Worker[]>([]);

const dipendentiAggiuntiItems = ref<Worker[]>([]);

const isNewEmployeePanelVisible = ref<boolean>(false);

let FormValidate: any;

const subscribedWorkersId = computed(() => {
    return companyParticipationRequests.value.map(cpr => cpr.student.workerId );
})

onMounted(async () => {
    corso.value = await courseService.getCourse(idCorso.value as string);
    if(isUserCompany.value){
        company.value = await profileService.getCompany(cesfUser.value.companyId);

        const lessonsFinder = {
            courseInstanceId: idCorso.value,
            sortField: "StartDate",
            sortOrder: SortingOrder.Ascending,
        } as LessonInstanceFinder;
        var respLessons = await courseService.getLessons(lessonsFinder);
        if (respLessons && respLessons.items) {
            lessons.value = respLessons.items;
        }

        await loadWorkers();
        await loadStudents();
        await loadCourseParticipationRequest();
    }

});

const loadData = async () =>{
    await loadWorkers();
    await loadStudents();
    await loadCourseParticipationRequest();
}

const loadWorkers = async (fullTextSearch?: string) => {
    var finderWorker: WorkerFinder = {
        companyId: cesfUser.value.companyId,
        globalSearchTearm: fullTextSearch
    } as WorkerFinder;

    var workerResponse = await employeeService.getWorkers(finderWorker);
    if (workerResponse && workerResponse.items) {
        dipendentiCercatiItems.value = workerResponse.items;
    }
}

const loadStudents = async () => {
    const studentFinder = {
        companyId: cesfUser.value.companyId
    } as StudentFinder;

    var workerResponse = await employeeService.getStudents(studentFinder);
    if (workerResponse && workerResponse.items) {
        companyStudents.value = workerResponse.items;
    }
}

const isAlreadySubscribed = (worker: Worker) : boolean => {
    return subscribedWorkersId.value.includes(worker.id);
}

const loadCourseParticipationRequest = async () => {
    const courseParticipationRequestFinder = {
        companyId: cesfUser.value.companyId,
        courseTemplateId: corso.value.courseTemplateId,
        queryRelations: true,
    } as CourseParticipationRequestFinder;

    var workerResponse = await instancesService.getCourseParticipationRequestsByFinder(courseParticipationRequestFinder);
    if (workerResponse && workerResponse.items) {
        companyParticipationRequests.value = workerResponse.items;
    }
}

const onAddEmployeeTosubscribe = function (dip: Worker, index: number) {
    dipendentiAggiuntiItems.value.push(dip);
    dipendentiCercatiItems.value.splice(index, 1);
};

const searchTimeout = ref<any>(null);
const onSearchEmployee = function (e: any) {
    clearTimeout(searchTimeout.value);

    searchTimeout.value = setTimeout(async () => {
        await loadWorkers(e.target.value);
    }, 300);
};

const placeOfCourse = computed(() => {
    if (lessons.value && lessons.value.length) {
        var lsp = lessons.value.find((x) => {
            return x.place && x.place.length;
        });

        if (lsp) {
            return lsp.place;
        }
    }

    return "";
});

const sendSubscriptionRequest = async () => {
    const request = dipendentiAggiuntiItems.value.map(da => {
        const relatedStudent = companyStudents.value.find(s => s.workerId == da.id);
        if(relatedStudent){
            return{
                companyId: cesfUser.value.companyId,
                courseTemplateId: corso.value.courseTemplateId,
                studentId: relatedStudent.id,
            } as SaveCourseParticipationRequestListItemRequest;
        } else{
            return{
                companyId: cesfUser.value.companyId,
                courseTemplateId: corso.value.courseTemplateId,
                saveStudentRequest: {
                    workerId: da.id,
                    toBeVerified: true,
                } as SaveStudentRequest,
            } as SaveCourseParticipationRequestListItemRequest;
        }
    } ) as SaveCourseParticipationRequestListItemRequest[];
    const response = await courseStore.saveCourseParticipationRequestsSave(request);
    if(response){
        await loadCourseParticipationRequest();
        await loadStudents();
        await loadWorkers();
        dipendentiAggiuntiItems.value = [];
    }
}
/*
onMounted(() => {
    const errorMessage =
        '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Attenzione</strong> Alcuni campi inseriti sono da controllare.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Chiudi avviso">';
    const errorWrapper = document.querySelector("#errorMsgContainer");
    const validate = new bootstrap.FormValidate("#justValidateForm", {
        errorFieldCssClass: "is-invalid",
        errorLabelCssClass: "form-feedback",
        errorLabelStyle: "",
        focusInvalidField: false
    });
    validate
        .addField("#validationCustom01", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            }
        ])
        .addField("#validationCustom02", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            }
        ])
        .addField("#validationCustom03", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            }
        ])
        .addField("#validationCustom04", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            }
        ])
        .addField("#validationCustomUsername", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            }
        ])
        .addField("#validationAge", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            },
            {
                rule: "minNumber",
                value: 18,
                errorMessage: "Deve essere maggiore di 17"
            }
        ])
        .addField("#validationCustom05", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            },
            {
                rule: "minLength",
                value: 5,
                errorMessage: "Inserire 5 cifre"
            },
            {
                rule: "maxLength",
                value: 5,
                errorMessage: "Inserire 5 cifre"
            },
            {
                rule: "number",
                errorMessage: "Inserire un numero"
            }
        ])
        .addField("#invalidCheck", [
            {
                rule: "required",
                errorMessage: "Questo campo è richiesto"
            }
        ])
        .onFail(() => {
            //(fields) => {
            errorWrapper.innerHTML = "";
            errorWrapper.innerHTML = errorMessage;
        });
});
*/
</script>

<style scoped lang="scss">
.kd-header-full {
    margin: -20px -30px -20px;
    padding: 20px;
    color: #333333;
    background-color: #f4f4f4;
}
.kd-already-sub {
    background-color: #f4f4f4;
}
.title-text {
    font-size: 40px;
}
.title-text-underline {
    text-decoration: underline;
}
.top-text {
    font-size: 14px;
    color: #757575;
}
.focus-text {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 400;
}
.exit-menu {
    color: #0066cc;
    font-size: 16px;
    //font-weight: 600;
    justify-content: flex-end;

    .exit-menu-icon {
        fill: #0066cc;
    }
}
.header-top-title,
.header-sub-detail {
    color: #757575;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
}
.btn-add-dipendente {
    opacity: 1;
    color: rgba(0, 102, 204, 1);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;
}
.list-row-dipendente-cf {
    opacity: 1;
    color: rgba(117, 117, 117, 1);
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.list-row-dipendente-name {
    opacity: 1;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.btn-aggiungi-dipendente {
    opacity: 1;
    color: rgba(0, 102, 204, 1);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;

    .icon {
        fill: rgba(0, 102, 204, 1);
        font-size: 16px;
    }
}
</style>
