import { defineStore } from "pinia";
import { ApiPagedResult, cmsService, CmsNode } from "@k-digitale/kappa";

export interface CesfCmsState {
    circolari: CmsNode[],
    news: CmsNode[],
    eventi: [],
    loading: boolean,
}

const NODE_CIRCOLARI = "listacircolari";
const NODE_NEWS = "listanews";

export const useCesfCmsStore = defineStore('cesf-cms-store', {

    state: () => ({
        circolari: [],
        news: [],
        eventi: [],
        loading: false,
    } as CesfCmsState),

    getters: {
        //
    },

    actions: {
        async getCircolari(): Promise<void> {
            this.loading = true;
            
            const result = await cmsService.getTreeFromNode(NODE_CIRCOLARI);
            this.circolari = result.childNodes;

            this.loading = false;
        },
        async getNews(): Promise<void> {
            this.loading = true;
            
            const result = await cmsService.getTreeFromNode(NODE_NEWS);
            this.news = result.childNodes;

            this.loading = false;
        },
    }
})