import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { Company } from '@/learning/registries/models';

class ModuleProfileService extends BaseService {

    async getCompany(id: string) : Promise<Company> {

        const response = await this.tryExecute(
            this.api.get<Company>(`learning/companies/${id}`)
        );

        return response!.data;
    }
}

export const profileService = new ModuleProfileService();