import { RouteRecordRaw } from "vue-router";

import Video from './pages/Video.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/video',
        component: Video,
        meta: { 
            requiresAuth: true,
            menu: {
                label: 'Video',
                iconAsset: 'icons/ico_video.svg'
            }
        }
    }
];

export default routes;