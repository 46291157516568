import { RouteRecordRaw } from 'vue-router';

/*** */
const modules = ['campaign', 'form', 'glossary'];
const optionalModules : { [index: string]: RouteRecordRaw[] } = {
};

import HomePage from './pages/HomeCompany.vue';
optionalModules.mod_home = [
    {
        path: '/',
        component: HomePage,
        meta: {
            requiresAuth: true,
            menu: {
                order: 0,
                label: 'Home',
                //icon: 'Home'
                iconAsset: "icons/ico_bacheca.svg"
            }
        }
    }
];


import profileRoutes from './modules/profile/routes';
optionalModules.mod_profile = profileRoutes;

import employeeRoutes from './modules/employee/routes';
optionalModules.mod_employee = employeeRoutes;

import certificationRoutes from './modules/certification/routes';
optionalModules.mod_certification = certificationRoutes;

import buildingSiteRoutes from './modules/buildingsite/routes';
optionalModules.mod_buildingsite = buildingSiteRoutes;

import courseRoutes from './modules/course/routes';
optionalModules.mod_course = courseRoutes;

//TODO Riaggiungere
// import serviceRoutes from './modules/service/routes';
// optionalModules.mod_service = serviceRoutes;

import communicationRoutes from './modules/communication/routes';
optionalModules.mod_communication = communicationRoutes;

import videoRoutes from './modules/webtv/routes';
optionalModules.mod_video = videoRoutes;

// import faqRoutes from './modules/faq/routes';
// optionalModules.mod_faq = faqRoutes;

// import helpdeskRoutes from './modules/helpdesk/routes';
// optionalModules.mod_helpdesk = helpdeskRoutes;


export { optionalModules };