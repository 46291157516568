import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { CourseInstance, CourseInstanceFinder, CourseParticipation, CourseParticipationFinder, CourseParticipationOutcome, CourseParticipationOutcomeFinder, LessonInstance, LessonInstanceFinder} from '../instances/Models'
import { Company, CompanyFinder, Student, StudentFinder, WorkerFinder, Worker, TeacherFinder, Teacher, TutorFinder, Tutor, CoordinatorFinder, Coordinator } from "./Models";
import { DeleteCompanyRequest, DeleteCoordinatorRequest, DeleteStudentRequest, DeleteTeacherRequest, DeleteTutorRequest, SaveCompanyRequest, SaveCoordinatorRequest, SaveStudentRequest, SaveTeacherRequest, SaveTutorRequest, SaveWorkerRequest, SendCompanyVerificationToCassaEdileRequest } from "./Requests";

class RegistriesService extends BaseService {
    //COMPANIES
    async getCompaniesByFinder(finder: CompanyFinder): Promise<ApiPagedResult<Company>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/companies", { params: finder })
        );

        return response!.data;
    }
    async getCompany(id: string): Promise<Company> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/companies/${id}`)
        );

        return response!.data;
    }
    async saveCompany(request: SaveCompanyRequest): Promise<string> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/companies/save-company", request)
        );

        return response?.data;
    }

    async sendCompanyToCassaEdileVerificationRequest(request: SendCompanyVerificationToCassaEdileRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/companies/send-to-cassa-edile", request)
        );

        return response?.data;
    }

    async getCourseParticipationsWithActualStudents(companyId:string, finder: CourseParticipationFinder): Promise<ApiPagedResult<CourseParticipation>> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/companies/${companyId}/course-participations`, { params: finder })
        );

        return response!.data;
    }

    async getCourseParticipationOutcomesWithActualStudents(companyId:string, finder: CourseParticipationOutcomeFinder): Promise<ApiPagedResult<CourseParticipationOutcome>> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/companies/${companyId}/course-participation-outcomes`, { params: finder })
        );

        return response!.data;
    }

    //STUDENTS
    async getStudentsByFinder(finder: StudentFinder): Promise<ApiPagedResult<Student>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/students", { params: finder })
        );

        return response!.data;
    }
    async getStudent(id: string): Promise<Student> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/students/${id}`)
        );

        return response!.data;
    }
    async saveStudent(request: SaveStudentRequest): Promise<string> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/students/save-student", request)
        );

        //return response!.data;
        return response?.data;
    }

    //TEACHERS

    async getTeachersByFinder(finder: TeacherFinder): Promise<ApiPagedResult<Teacher>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/teachers", { params: finder })
        );

        return response!.data;
    }
    async getTeacher(id: string): Promise<Teacher> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/teachers/${id}`)
        );

        return response!.data;
    }
    async saveTeacher(request: SaveTeacherRequest): Promise<string> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/teachers/save", request)
        );

        //return response!.data;
        return response?.data;
    }

    //TUTORS

    async getTutorsByFinder(finder: TutorFinder): Promise<ApiPagedResult<Tutor>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/tutors", { params: finder })
        );

        return response!.data;
    }
    async getTutor(id: string): Promise<Tutor> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/tutors/${id}`)
        );

        return response!.data;
    }
    async saveTutor(request: SaveTutorRequest): Promise<string> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/tutors/save", request)
        );

        //return response!.data;
        return response?.data;
    }

    //COORDINATORS

    async getCoordinatorsByFinder(finder: CoordinatorFinder): Promise<ApiPagedResult<Coordinator>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/coordinators", { params: finder })
        );

        return response!.data;
    }
    async getCoordinator(id: string): Promise<Coordinator> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/coordinators/${id}`)
        );

        return response!.data;
    }
    async saveCoordinator(request: SaveCoordinatorRequest): Promise<string> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/coordinators/save", request)
        );

        //return response!.data;
        return response?.data;
    }

    //WORKERS
    async getWorkersByFinder(finder: WorkerFinder): Promise<ApiPagedResult<Worker>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/workers", { params: finder })
        );

        return response!.data;
    }

    async saveWorker(request: SaveWorkerRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/workers/save", request)
        );

        //return response!.data;
        return response?.data.success;
    }
}

export const registriesService = new RegistriesService();
