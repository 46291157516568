import { defineStore } from "pinia";
import { Cantiere, CantiereFinder } from "@/services/cesf/models";
import { cesfService } from "@/services/cesf/cesfServices";
import { ApiPagedResult } from "@k-digitale/kappa";

export interface ModuleBuildingSiteState {
  currentCantiere: Cantiere,
  cantieri: Cantiere[],
  loadingCantieri: boolean,
  loadingCantiere: boolean,
  loadingDocumentiCantiere: boolean,

}


export const useBuildingSiteStore = defineStore('module-buildingsite-store', {

  state: () => ({
    currentCantiere: {},
    cantieri: [],
    loadingCantieri: false,
    loadingCantiere: false,
    loadingDocumentiCantiere: false
  } as ModuleBuildingSiteState),

  getters: {
    //
  },

  actions: {
    async getCantieriByCompany(finder: CantiereFinder, companyId: string): Promise<ApiPagedResult<Cantiere>> {
      this.loadingCantieri = true;
      const result = await cesfService.getCantieriByEduCompanyId(finder, companyId);
      this.loadingCantieri = false;
      return result;
    },

    async getCantiereById(id: string): Promise<Cantiere> {
      this.loadingCantiere = true;
      const result = await cesfService.getCantiere(id);
      this.loadingCantiere = false;
      return result;
    },

    // DOCUMENTI CANTIERE
    async getDocumentiCantiere(id: string) : Promise<any[]>{
      this.loadingDocumentiCantiere = true;
      try {
        const response = await cesfService.getDocumentiCantiere(id);
        const result = response.items;
        this.loadingDocumentiCantiere = false;
        return result;
      }
      finally { this.loadingDocumentiCantiere = false; }
    },

    async uploadDocumentoCantiere(id: string, formData: FormData) : Promise<boolean> {
      this.loadingDocumentiCantiere = true;
      try {
        //const result = await execApiCall(documentsService.saveTeacherFile(workerId, formData));
        const result = await cesfService.uploadDocumentoCantiere(id, formData);
        this.loadingDocumentiCantiere = false;
        return result;
      }
      finally { this.loadingDocumentiCantiere = false; }
    },

    async downloadDocumentoCantiere(id : string, idDocumento : number, filename: string) : Promise<void> {
        // this.loadingDocumentiCantiere = true;
        const result = await cesfService.downloadCantieriDocument(id, idDocumento, filename);
        // this.loadingDocumentiCantiere = false;
    },

    async deleteDocumentoCantiere(id : string, idDocumento : number) : Promise<boolean> {
      // this.loadingDocumentiCantiere = true;
      const result = await cesfService.deleteDocumentoCantiere(id, idDocumento);
      // this.loadingDocumentiCantiere = false;
      return result;
    },
  }
})