import { GlobalSearchPlugin, GlobalSearchResult } from "@k-digitale/kappa";

class WebtvGlobalSearchPlugin implements GlobalSearchPlugin{

    source = "web-tv";
    sourceDescription = "Web TV";

    _lastSearch = "";

    async searchAsync(searchText: string) : Promise<GlobalSearchResult[]> {
        const results = [
            {
                source: this.source,
                title: "Mario Rossi " + searchText,
                description: "lorem ipsum dolor ecc ecc",
                url: "/video"
            },
            {
                source: this.source,
                title: "Mario Rossi 1 " + searchText,
                description: "lorem ipsum dolor ecc ecc",
                url: "/video"
            },
            {
                source: this.source,
                title: "Mario Rossi 2 " + searchText,
                description: "lorem ipsum dolor ecc ecc",
                url: "/video"
            },
            {
                source: this.source,
                title: "Mario Rossi 3" + searchText,
                description: "lorem ipsum dolor ecc ecc",
                url: "/video"
            },
        ];
        this._lastSearch = searchText;
        return results;
    }

    async loadMoreAsync() : Promise<GlobalSearchResult[]> {
        const results = [
            {
                source: this.source,
                title: "[EXT] trallalero trallallà 1 " + this._lastSearch,
                description: "lorem ipsum dolor ecc ecc",
                url: "https://www.google.it/search?q=test",
                urlExternal: true
            },
            {
                source: this.source,
                title: "trallalero trallallà 2 " + this._lastSearch,
                description: "lorem ipsum dolor ecc ecc",
                url: "/video"
            },
            {
                source: this.source,
                title: "trallalero trallallà 3 " + this._lastSearch,
                description: "lorem ipsum dolor ecc ecc",
                url: "/video"
            }
        ];
        return results;
    }    
}

export default new WebtvGlobalSearchPlugin();