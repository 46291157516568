import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { Worker, Student, StudentFinder, WorkerFinder } from "@/learning/registries/models";

class ModuleEmployeeService extends BaseService {

    async getWorkers(finder: WorkerFinder) : Promise<ApiPagedResult<Worker>> {

        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<Worker>>(`learning/workers`, { params: finder })
        );

        return response!.data;
    }

    async getWorker(id: string) : Promise<Worker> {
        const response = await this.tryExecute(
            this.api.get<Worker>(`learning/workers/${id}`)
        );

        return response!.data;
    }

    async getStudents(finder: StudentFinder) : Promise<ApiPagedResult<Student>> {
        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<Student>>(`learning/students`, { params: finder })
        );

        return response!.data;
    }

    async getStudent(id: string) : Promise<Student> {
        const response = await this.tryExecute(
            this.api.get<Student>(`learning/students/${id}`)
        );

        return response!.data;
    }

    async getWorkerQualifications() : Promise<Student> {
        const response = await this.tryExecute(
            this.api.get<Student>(`lookups/worker-qualifications`)
        );

        return response!.data;
    }
}


export const employeeService = new ModuleEmployeeService();