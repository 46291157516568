import { RouteRecordRaw } from "vue-router";

import Certification from './pages/Certification.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/certification',
        component: Certification,
        meta: { 
            requiresAuth: true,
            menu: {
                label: 'Certificazioni',
                iconAsset: 'icons/ico_certificazioni.svg'
            }
        }
    }
];

export default routes;