import { App }  from 'vue';
import PrimeVue from 'primevue/config';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import ConfirmDialog  from 'primevue/confirmdialog';
import Dialog from 'primevue/Dialog';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Steps from 'primevue/steps';
import Skeleton from 'primevue/skeleton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';


const addUiFramework = (app: App<Element>) => {
    app.use(PrimeVue);
    app.use(ToastService);
    app.use(ConfirmationService);

    app.directive('tooltip', Tooltip);

    app.component('Accordion', Accordion);
    app.component('AccordionTab', AccordionTab);
    app.component('BlockUI', BlockUI);
    app.component('Button', Button);
    app.component('Calendar', Calendar);
    app.component('Card', Card);
    app.component('Checkbox', Checkbox);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('Chip', Chip);
    app.component('Dialog', Dialog);
    app.component('Dropdown', Dropdown);
    app.component('FileUpload', FileUpload);
    app.component('InputText', InputText);
    app.component('Message', Message);
    app.component('OverlayPanel', OverlayPanel);
    app.component('Paginator', Paginator);
    app.component('Panel', Panel);
    app.component('Password', Password);
    app.component('ProgressBar', ProgressBar);
    app.component('ProgressSpinner', ProgressSpinner);
    app.component('RadioButton', RadioButton);
    app.component('SelectButton', SelectButton);
    app.component('Sidebar', Sidebar);
    app.component('Steps', Steps);
    app.component('Skeleton', Skeleton);
    app.component('TabView', TabView);
    app.component('TabPanel', TabPanel);
    app.component('Toast', Toast);
    app.component('Tree', Tree);
    
    app.component('DataTable', DataTable);
    app.component('Column', Column);
    
}

export default addUiFramework;