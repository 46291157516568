import { RouteRecordRaw } from "vue-router";

import Course from './pages/Course.vue';
import CourseRegistration from './pages/CourseRegistration.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/course',
        component: Course,
        meta: { 
            requiresAuth: true,
            menu: {
                label: 'Corsi',
                iconAsset: 'icons/ico_corsi.svg'
            }
        }
    },
    {
        path: '/course/registration/:id',
        component: CourseRegistration,
        meta: { 
            requiresAuth: true,
            menu: {
                label: 'Iscrizione corso',
                iconAsset: 'icons/ico_corsi.svg',
                hidden: true
            }
        }
    }
];

export default routes;